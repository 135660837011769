import {createService,  updateCommonDetails} from '../services/authService';
var baseUrl = process.env.REACT_APP_BASE_API_URL;

const serviceHandler = createService();

const processService = {
  ProcessRegistryWebFetch: (data: any,siteUniqueId:any,languageCode:any) => serviceHandler.handleRequest(`${baseUrl}/ProcessRegistryWebFetch`, data, 'POST', '', siteUniqueId, languageCode),
  ProcessRegistryWebAction: (data: any,siteUniqueId:any,languageCode:any) => serviceHandler.handleRequest(`${baseUrl}/ProcessRegistryWebAction`, data,"POST","",siteUniqueId,languageCode),

};

const blobServices = {
  blobStorage: (data: any, fileType: any) => serviceHandler.handleRequest(`${baseUrl}BlobStorage`, data, "POST", fileType,"",""),
};

export {
  processService,
  blobServices,
};
