import { useEffect, useRef, useState } from "react";
import VoiceSearchInput from "../../form/voice-search-input";
import QuestionCard from "../../UI/question-card";
import ModalPageWrapper from "../../global-layout/modal-page-wrapper";
import IconBtn from "../../form/icon-btn";
import { DownArrowIcon, PageModalCloseIcon } from "../../svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import Skeleton from "react-loading-skeleton";

const processData = [
  {
    screenId: "Screen1",
    name: "Screen 1",
    description: "asdasd",
    screenDetails: [
      {
        id: "1-atm",
        groupId: 1,
        type: 1,
        subType: 0,
        displayText: "Tenant Name",
        createdAt: "1/4/2024 12:46:10.47",
        questionType: 1,
        name: "Question",
        inputType: 1,
        inputSubType: 1,
        inputOptions: [],
        convertCase: "0",
        isMandatory: true,
        isEvidence: true,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "2-gqw",
        groupId: 1,
        type: 2,
        subType: 0,
        displayText: "Site Name",
        createdAt: "1/4/2024 12:46:10.507",
        questionType: 2,
        name: "Question",
        inputType: 2,
        inputSubType: 2,
        inputOptions: [],
        convertCase: "0",
        isMandatory: true,
        isEvidence: true,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "3-xd7",
        groupId: 1,
        type: 3,
        subType: 0,
        displayText: "Person Name",
        createdAt: "1/4/2024 12:46:10.533",
        questionType: 3,
        name: "Question",
        inputType: 3,
        inputSubType: 3,
        inputOptions: [],
        convertCase: "0",
        isMandatory: true,
        isEvidence: true,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "4-zds",
        groupId: 1,
        type: 4,
        subType: 0,
        displayText: "Location",
        createdAt: "1/4/2024 12:46:10.567",
        questionType: 4,
        name: "Question",
        inputType: 4,
        inputSubType: 4,
        inputOptions: [],
        convertCase: "0",
        isMandatory: true,
        isEvidence: true,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "5-uad",
        groupId: 2,
        type: 5,
        subType: 0,
        displayText: "Scanner - All",
        createdAt: "1/4/2024 12:46:10.597",
        questionType: 5,
        name: "Question",
        inputType: 5,
        inputSubType: 5,
        inputOptions: [],
        convertCase: "0",
        isMandatory: true,
        isEvidence: true,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "9-kpk",
        groupId: 2,
        type: 6,
        subType: 0,
        displayText: "Multiple Choice - Radio",
        createdAt: "1/4/2024 12:46:10.717",
        questionType: 9,
        name: "Question",
        inputType: 6,
        inputSubType: 6,
        inputOptions: [],
        convertCase: "0",
        isMandatory: true,
        isEvidence: true,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "12-p07",
        groupId: 2,
        type: 7,
        subType: 0,
        displayText: "Text Short",
        createdAt: "1/4/2024 12:46:10.81",
        questionType: 12,
        name: "Question",
        inputType: 7,
        inputSubType: 7,
        inputOptions: [],
        convertCase: "0",
        isMandatory: true,
        isEvidence: true,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "14-qun",
        groupId: 2,
        type: 8,
        subType: 0,
        displayText: "Number",
        createdAt: "1/4/2024 12:46:10.87",
        questionType: 14,
        name: "Question",
        inputType: 8,
        inputSubType: 8,
        inputOptions: [],
        convertCase: "0",
        isMandatory: true,
        isEvidence: true,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "15-dga",
        groupId: 2,
        type: 9,
        subType: 0,
        displayText: "Date",
        createdAt: "1/4/2024 12:46:10.897",
        questionType: 15,
        name: "Question",
        inputType: 9,
        inputSubType: 9,
        inputOptions: [],
        convertCase: "0",
        isMandatory: true,
        isEvidence: true,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "18-irb",
        groupId: 2,
        type: 10,
        subType: 0,
        displayText: "Acknowledgment",
        createdAt: "1/4/2024 12:46:10.99",
        questionType: 18,
        name: "Question",
        inputType: 10,
        inputSubType: 10,
        inputOptions: [],
        convertCase: "0",
        isMandatory: true,
        isEvidence: true,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "19-ywg",
        groupId: 2,
        type: 11,
        subType: 0,
        displayText: "Information",
        createdAt: "1/4/2024 12:46:11.02",
        questionType: 19,
        name: "Question",
        inputType: 11,
        inputSubType: 11,
        inputOptions: [],
        convertCase: "0",
        isMandatory: true,
        isEvidence: true,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "20-zpd",
        groupId: 2,
        type: 12,
        subType: 0,
        displayText: "Signature",
        createdAt: "1/4/2024 12:46:11.047",
        questionType: 20,
        name: "Question",
        inputType: 12,
        inputSubType: 12,
        inputOptions: [],
        convertCase: "0",
        isMandatory: false,
        isEvidence: false,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "21-9ts",
        groupId: 2,
        type: 13,
        subType: 0,
        displayText: "Image",
        createdAt: "1/4/2024 12:46:11.077",
        questionType: 21,
        name: "Question",
        inputType: 13,
        inputSubType: 13,
        inputOptions: [],
        convertCase: "0",
        isMandatory: false,
        isEvidence: false,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "23-282",
        groupId: 2,
        type: 15,
        subType: 0,
        displayText: "Video",
        createdAt: "1/4/2024 12:46:11.137",
        questionType: 23,
        name: "Question",
        inputType: 15,
        inputSubType: 15,
        inputOptions: [],
        convertCase: "0",
        isMandatory: false,
        isEvidence: false,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "24-akq",
        groupId: 2,
        type: 16,
        subType: 0,
        displayText: "Magic Container",
        createdAt: "1/4/2024 12:46:11.163",
        questionType: 24,
        name: "Question",
        inputType: 16,
        inputSubType: 16,
        inputOptions: [],
        convertCase: "0",
        isMandatory: false,
        isEvidence: false,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "24-akq",
        groupId: 2,
        type: 18,
        subType: 0,
        displayText: "Magic Container",
        createdAt: "1/4/2024 12:46:11.163",
        questionType: 18,
        name: "Damaged Vehicle Zone",
        inputType: 16,
        inputSubType: 16,
        inputOptions: [],
        convertCase: "0",
        isMandatory: false,
        isEvidence: false,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
    ],
  },
  {
    screenId: "screen2",
    name: "screen 2",
    description: "dsfsdf",
    screenDetails: [
      {
        id: "2-i49",
        groupId: 1,
        type: 2,
        subType: 0,
        displayText: "Site Name",
        createdAt: "1/4/2024 12:46:10.507",
        questionType: 2,
        name: "Question",
        inputType: 2,
        inputSubType: 2,
        inputOptions: [],
        convertCase: "0",
        isMandatory: false,
        isEvidence: false,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "3-wpd",
        groupId: 1,
        type: 3,
        subType: 0,
        displayText: "Person Name",
        createdAt: "1/4/2024 12:46:10.533",
        questionType: 3,
        name: "Question",
        inputType: 3,
        inputSubType: 3,
        inputOptions: [],
        convertCase: "0",
        isMandatory: false,
        isEvidence: false,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "4-qeg",
        groupId: 1,
        type: 4,
        subType: 0,
        displayText: "Location",
        createdAt: "1/4/2024 12:46:10.567",
        questionType: 4,
        name: "Question",
        inputType: 4,
        inputSubType: 4,
        inputOptions: [],
        convertCase: "0",
        isMandatory: false,
        isEvidence: false,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
      {
        id: "5-osd",
        groupId: 2,
        type: 5,
        subType: 0,
        displayText: "Scanner - All",
        createdAt: "1/4/2024 12:46:10.597",
        questionType: 5,
        name: "Question",
        inputType: 5,
        inputSubType: 5,
        inputOptions: [],
        convertCase: "0",
        isMandatory: false,
        isEvidence: false,
        validation: [],
        bindValue: "@Unit_details",
        displayValue: ["@destination"],
      },
    ],
  },
  {
    screenId: "screen3",
    name: "screen 3",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen4",
    name: "screen 4",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen5",
    name: "screen 5",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen6",
    name: "screen 6",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen7",
    name: "screen 7",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen8",
    name: "screen 8",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen9",
    name: "screen 9",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen10",
    name: "screen 10",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen11",
    name: "screen 11",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen12",
    name: "screen 12",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen13",
    name: "screen 13",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen14",
    name: "screen 14",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen15",
    name: "screen 15",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen16",
    name: "screen 16",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen17",
    name: "screen 17",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen18",
    name: "screen 18",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen19",
    name: "screen 19",
    description: "dsfsdf",
    screenDetails: [],
  },
  {
    screenId: "screen20",
    name: "screen 20",
    description: "dsfsdf",
    screenDetails: [],
  },
];

interface OptionTwoMainProps {
  isLoading: boolean;
}

function OptionTwoMain({ isLoading }: OptionTwoMainProps) {
  const [selectedScreen, setSelectedScreen] = useState<any>(processData[0]);
  const [selectedScreenId, setSelectedScreenId] = useState<any>(1);
  const [showImagePreview, setShowImagePreview] = useState<boolean>(false);
  const [previewImageSrc, setPreviewImageSrc] = useState<any>(null);
  const [showTabSearch, setShowTabSearch] = useState<boolean>(false);
  const [tabSearchData, setTabSearchData] = useState<any>(processData);
  const [allProcessData, setAllProcessData] = useState<any>(processData);
  const tabSearchRef = useRef<any>(null);
  const tabSearchButton = useRef<any>(null);

  const handleScreenClick = (screen: any) => {
    setSelectedScreen(screen);
    setSelectedScreenId(screen.id);
  };

  useEffect(() => {
    // console.log(selectedScreen);
  }, [selectedScreen]);

  const expandPreviewImage = (status: boolean, data: any) => {
    setShowImagePreview(status);
    setPreviewImageSrc(data.imgSrc);
  };

  const handleSearch = (e: any) => {
    const value = e.target.value;
    filterTabSearchData(value);
  };

  const filterTabSearchData = (searchQuery: string) => {
    const filteredData = processData.filter((item) => {
      return item.screenId.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setTabSearchData(filteredData);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tabSearchRef.current &&
        !tabSearchRef.current.contains(event.target) &&
        tabSearchButton.current &&
        !tabSearchButton.current.contains(event.target)
      ) {
        setShowTabSearch(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="w-full h-auto relative">
        <div className="w-full h-auto">
          {isLoading ? (
            <Skeleton
              width="100%"
              height={50}
              borderRadius={3}
              style={{ marginBottom: "10px" }}
            />
          ) : (
            <div className="w-full h-auto flex justify-between items-center gap-4 mb-5 border-b border-io-gray-c8 dark:border-io-black-48">
              <div className="w-[4rem] relative">
                <button
                  ref={tabSearchButton}
                  type="button"
                  className="w-[3.5rem] h-[3.5rem] bg-io-primary rounded-[0.5rem] flex justify-center items-center"
                  onClick={() => setShowTabSearch(!showTabSearch)}
                >
                  <div className="w-[2rem] h-auto">
                    <DownArrowIcon />
                  </div>
                </button>
                {showTabSearch && (
                  <div
                    ref={tabSearchRef}
                    className="w-[35rem] h-[40vh] bg-io-white dark:bg-io-black-1c shadow-lg rounded-[0.6rem] absolute top-full left-0 z-[3]"
                  >
                    <div className="w-full h-full flex flex-col p-8">
                      <VoiceSearchInput
                        withMic={false}
                        inputSize="small"
                        handleSearch={handleSearch}
                      />
                      <div className="flex-auto basis-0 overflow-y-auto scrollbar-large mt-4">
                        {tabSearchData?.length > 0 ? (
                          tabSearchData.map((item: any, i: number) => (
                            <div
                              key={i}
                              className="w-full h-auto mb-2 cursor-pointer group "
                              onClick={() => handleScreenClick(item)}
                            >
                              <div
                                className={`w-full h-auto px-[1.2rem] py-4 rounded-[0.3rem] ${
                                  selectedScreen.screenId === item.screenId
                                    ? "bg-[#F2F8FF] dark:bg-[#182233]"
                                    : "bg-io-white dark:bg-transparent"
                                } group-hover:bg-[#F2F8FF] dark:group-hover:bg-[#182233]`}
                              >
                                <p
                                  className={`text-[1.4rem] font-inter font-normal ${
                                    selectedScreen.screenId === item.screenId
                                      ? "text-io-primary"
                                      : "text-io-gray-66 dark:text-io-black-d1"
                                  }  group-hover:text-io-primary`}
                                >
                                  {item.screenId}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 text-center italic">
                            No data Found
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-[97%] h-auto">
                <div className="w-full h-auto">
                  <Swiper speed={1000} spaceBetween={10} slidesPerView={"auto"}>
                    {processData.length > 0 &&
                      processData.map((slider: any, index: any) => (
                        <SwiperSlide key={index}>
                          <div
                            className="w-auto h-auto"
                            onClick={() => handleScreenClick(slider)}
                          >
                            <div
                              className={`w-auto h-auto px-[2.3rem] py-[0.7rem] cursor-pointer ${
                                selectedScreen.screenId === slider.screenId
                                  ? "bg-io-primary text-io-white"
                                  : "bg-io-gray-f7 text-io-gray-66 dark:text-io-black-d1 dark:bg-io-black-23"
                              }  rounded-t-[1rem]`}
                            >
                              <p className="text-[1.8rem] font-inter font-normal  text-center text-nowrap">
                                {slider.screenId}
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full h-auto ">
          <p className="text-[1.6rem] font-inter font-normal text-io-gray-9d italic">
            {" "}
            {isLoading ? (
              <Skeleton
                width={200}
                height={20}
                borderRadius={3}
                style={{ marginBottom: "10px" }}
              />
            ) : (
              <span>
                {selectedScreen?.screenDetails.length} Questions Found
              </span>
            )}{" "}
          </p>
          {selectedScreen?.screenDetails.length > 0 &&
            selectedScreen?.screenDetails.map(
              (screendetail: any, i: number) => (
                <QuestionCard
                  item={screendetail}
                  fullProcessData={screendetail}
                  setAllProcessData={setAllProcessData}
                  selectedScreenId={selectedScreenId}
                  index={i}
                  expandPreviewImage={expandPreviewImage}
                  isLoading={isLoading}
                />
              )
            )}
        </div>
      </div>
      {showImagePreview && (
        <ModalPageWrapper>
          <div className="w-full h-full flex justify-center items-center">
            <div className="w-[60%] h-auto bg-io-white  rounded-[2.2rem] max-md:w-[68%]">
              <div className="w-full h-auto p-12 text-center">
                <div className="w-full h-auto flex justify-end mb-5">
                  <IconBtn
                    size="w-[3rem]"
                    icon={<PageModalCloseIcon />}
                    handleClick={() => setShowImagePreview(false)}
                  />
                </div>
                <div className="w-full h-auto">
                  {previewImageSrc && (
                    <img
                      src={require(`../../../assets/images/${previewImageSrc}`)}
                      alt="PreviewImage"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalPageWrapper>
      )}
    </>
  );
}
export default OptionTwoMain;
