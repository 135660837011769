import { useEffect, useState } from "react";
import PageHeader from "../../global-layout/page-header"
import OptionTwoMain from "../option-two-main";
import ProcessDetailHeader from "../process-detail-header";

function OptionTwoWrapper(){
    const breadcrumbs = [{ label: "Process", link: "/" }, { label: "LSJWH4092R" , link: undefined }];
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 5000);
    },[])
    return(
        <div className="w-full h-auto">
            <PageHeader title="Name “LSJWH4092R”" breadcrumbs={breadcrumbs} hasSaveBtn={true} isLoading={isLoading}/>
            <ProcessDetailHeader isLoading={isLoading}/>
            <OptionTwoMain isLoading={isLoading}/>
        </div>
    )
}
export default OptionTwoWrapper