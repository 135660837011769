import OptionOneWrapper from "../components/section/option-one-wrapper";

function OptionOne() {
  return (
    <div className="flex-1 main-area bg-io-white dark:bg-io-black-15 overflow-auto">
      <div className="w-full h-full px-28 pb-12 max-xl:px-14 max-2xl:px-16 max-3xl:px-20">
        <div className="w-full h-full">
          <OptionOneWrapper />
        </div>
      </div>
    </div>
  );
}
export default OptionOne;
