import OptionTwoWrapper from "../components/section/option-two-wrapper"

function OptionTwo(){
    return(
        <div className="flex-1 main-area bg-io-white dark:bg-io-black-15 overflow-auto">
        
        <div className="w-full h-full px-28 py-12 ">
            <div className="w-full h-full">
            <OptionTwoWrapper />
            </div>
        </div>
    </div>
    )
}
export default OptionTwo