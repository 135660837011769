import { ReactNode, useEffect, useRef } from "react";
interface ModalPageWrapperProps {
  withoutOverlay?: boolean;
  onClose?: () => void;
  children: ReactNode;
}
function ModalPageWrapper({
  withoutOverlay = false,
  children,
  onClose,
}: ModalPageWrapperProps) {
  const modalRef = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (modalRef.current && modalRef.current == event.target) {
        onClose?.();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`w-full h-[calc(100vh - 6rem)] absolute top-0 left-0 bottom-0 right-0 z-10 ${
        withoutOverlay ? "bg-transparent" : "bg-[#00000033]"
      }`}
    >
      <div ref={modalRef} className="w-full h-full">
        {children}
      </div>
    </div>
  );
}
export default ModalPageWrapper;
