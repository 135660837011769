import { Fragment, useState, useEffect } from "react";
import { TextAreaField, InputFieldWithoutForm } from "../../form/input-fields";
import { ToggleButtonWithoutForm } from "../../form/toggle-btn";
import {
  ExpandPrimaryIcon,
  ProcessMoreInfoIcon,
  ResponseLocationIcon,
  ResponseNumberIcon,
  ResponsePersonNameIcon,
  ResponseScannerIcon,
  ResponseSiteNameIcon,
  ResponseTenantNameIcon,
  ResponseTextIcon,
} from "../../svg";
import ImageUpload from "../../form/image-upload";
import RadioBtns from "../../form/radio-btns";
import CheckBoxGroup from "../../form/checkbox-group";
import {
  SelectInputWithoutForm,
  MultiSelectInputWithoutForm,
} from "../../form/select-input";
import VideoUpload from "../../form/video-upload";
import AudioUpload from "../../form/audio-upload";
import DamagedInputPreview from "../damaged-input-preview";
import MagicContainerDetail from "../magic-container-detail";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import SignaturePadTest from "../signature-pad-test";
import IconBtn from "../../form/icon-btn";
import defaultImage from "../../../assets/images/default.png";
function QuestionCard({
  item,
  fullProcessData,
  setAllProcessData,
  selectedScreenId,
  index,
  expandPreviewImage,
  isLoading,
  isViewOnly,
  validationErrors,
  handleImagePreview,
}: any) {
  const [isOnlyMagicContainer] = useState(
    fullProcessData?.screenDetail?.isOnlyMagicContainer
  );
  const { t } = useTranslation();
  const [evidenceCount, setEvidenceCount] = useState(0);

  const getDefaultAnswer = (questionuniqueId: any, type: any) => {
    const answerDetail =
      fullProcessData.screenDetail?.answerDetail?.[selectedScreenId]?.[
        questionuniqueId
      ];
    if (!answerDetail) return [];
    switch (type) {
      case 1:
        return answerDetail.defaultAnswer || [];
      case 2:
        return answerDetail.evidenceDetail || [];
      case 3:
        return answerDetail.noteDetail || [];
      default:
        return [];
    }
  };

  const getDefaultInputValueAnswer = (questionuniqueId: string) => {
    // Check if questionuniqueId exists in typeDetail
    const typeDetail =
      fullProcessData.screenDetail?.typeDetail?.[questionuniqueId];
    if (!typeDetail) return [];

    const multiChoice = typeDetail.multiChoiceDetailsKey;
    if (!multiChoice) return [];

    const masterDetail =
      fullProcessData.screenDetail?.masterDetail?.[multiChoice];
    return masterDetail || [];
  };

  useEffect(() => {}, [
    fullProcessData,
    fullProcessData.screenDetail?.answerDetail,
  ]);

  const handleInputChange = (
    questionUniqueId: any,
    name: any,
    value: any,
    isMultiSelect: boolean
  ) => {
    const updatedData = { ...fullProcessData }; // Shallow copy of the original data
    if (isMultiSelect) {
      const currentValues =
        updatedData?.screenDetail?.answerDetail[selectedScreenId][
          questionUniqueId
        ][name] || [];
      const valueIndex = currentValues.findIndex((v: any) => v.id === value.id);

      if (valueIndex === -1) {
        // Value not present, add it
        updatedData.screenDetail.answerDetail[selectedScreenId][
          questionUniqueId
        ][name] = [...currentValues, value];
      } else {
        // Value present, remove it
        updatedData.screenDetail.answerDetail[selectedScreenId][
          questionUniqueId
        ][name] = currentValues.filter((v: any) => v.id !== value.id);
      }
    } else {
      // For single select, just replace the value
      updatedData.screenDetail.answerDetail[selectedScreenId][questionUniqueId][
        name
      ] = value;
    }
    setAllProcessData(updatedData); // Update state with the modified data
  };

  const renderResponseComponent = (item: any) => {
    const componentMap: { [key: string]: any } = {
      1: (
        <ResponseTenantName
          questionData={item}
          validationErrors={validationErrors}
          selectedScreenId={selectedScreenId}
          fullProcessData={fullProcessData}
          handleInputChange={handleInputChange}
        />
      ),
      2: (
        <ResponseSiteName
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
        />
      ),
      3: (
        <ResponsePersonName
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
        />
      ),
      4: (
        <ResponseLocation
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
        />
      ),
      5: (
        <ResponseScanner
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
        />
      ),
      6: (
        <ResponseMultipleChoice
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          getDefaultInputValueAnswer={getDefaultInputValueAnswer}
          handleInputChange={handleInputChange}
        />
      ),
      7: (
        <ResponseText
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
        />
      ),
      8: (
        <ResponseNumber
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
        />
      ),
      9: (
        <ResponseDateTime
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
        />
      ),
      10: (
        <ResponseAcknowledgement
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
        />
      ),
      11: (
        <ResponseInformation
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
        />
      ),
      12: (
        <ResponseSignature
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
          selectedScreenId={selectedScreenId}
          fullProcessData={fullProcessData}
          setAllProcessData={setAllProcessData}
        />
      ),
      13: (
        <ResponseImage
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
          selectedScreenId={selectedScreenId}
          fullProcessData={fullProcessData}
          setAllProcessData={setAllProcessData}
          handleImagePreview={handleImagePreview}
        />
      ),
      14: (
        <DamageInput
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
          expandPreviewImage={expandPreviewImage}
        />
      ),
      15: (
        <ResponseVideo
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
          selectedScreenId={selectedScreenId}
          fullProcessData={fullProcessData}
          setAllProcessData={setAllProcessData}
        />
      ),
      16: (
        <ResponseMagicContainer
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
          expandPreviewImage={expandPreviewImage}
          handleImagePreview={handleImagePreview}
        />
      ),
      17: (
        <ResponseAudio
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
          selectedScreenId={selectedScreenId}
          fullProcessData={fullProcessData}
          setAllProcessData={setAllProcessData}
        />
      ),
      18: (
        <ResponseToggle
          questionData={item}
          validationErrors={validationErrors}
          getDefaultAnswer={getDefaultAnswer}
          handleInputChange={handleInputChange}
          expandPreviewImage={expandPreviewImage}
        />
      ),
    };
    return componentMap[item.type] || <p>Not found</p>;
  };

  return (
    <>
      {isLoading ? (
        <Skeleton
          width="100%"
          height={180}
          borderRadius={15}
          style={{ marginBottom: "10px" }}
        />
      ) : (
        <div className="w-full h-auto group">
            <div
          key={index}
          className={`w-full h-auto rounded-[1.5rem] px-[2.5rem] py-[2.5rem] select-none border border-[#f0f0f0] dark:border-io-black-48 bg-io-white group-hover:bg-[#F4F9FD] dark:bg-io-black-15 dark:group-hover:bg-io-black-1c mb-[3rem] overflow-hidden relative before:content-[''] before:w-[1rem] before:h-full before:bg-[#f3f3f3] group-hover:before:bg-io-primary dark:before:bg-io-black-48 before:absolute before:top-0 before:left-0 `}
        >
          {isOnlyMagicContainer !== 1 && (
            <>
              {item.type !== 10 && item.type !== 11 && (
                <div className="w-full h-auto flex justify-between items-center mb-[2rem]">
                  <p className="text-[1.8rem] font-inter font-medium text-io-black dark:text-io-white">
                    {index + 1}. {item.title}
                    {item.isMandatory === 1 && (
                      <span className="text-red-500">*</span>
                    )}
                  </p>
                </div>
              )}
            </>
          )}

          <div className="w-full h-auto">{renderResponseComponent(item)}</div>
          {item.isEvidence === 1 && (
            <div className="w-full h-auto mt-16">
              <div className="w-full h-auto py-8 border-t border-dashed border-io-gray-c8">
                <p className="text-[1.6rem] font-inter font-medium text-io-black dark:text-io-white mb-5">
                  Evidence ({evidenceCount})
                </p>
                <div className="w-auto h-auto">
                  <ImageUpload
                    id={index}
                    uploadedImageCount={(count: any) => setEvidenceCount(count)}
                    selectedScreenId={selectedScreenId}
                    questionUniqueId={item.id}
                    fullProcessData={fullProcessData}
                    setAllProcessData={setAllProcessData}
                    isEvidence={1}
                    handleImagePreview={handleImagePreview}
                  />
                </div>
              </div>
            </div>
          )}
          {item.isNote === 1 && (
            <div className="w-full h-auto">
              <div className="w-full h-auto pt-8 border-t border-dashed border-io-gray-c8">
                <p className="text-[1.6rem] font-inter font-medium text-io-black dark:text-io-white mb-5">
                  Notes
                </p>
                <div className="w-full h-auto">
                  <TextAreaField
                    labelName={t("web.common.notes")}
                    type="text"
                    id="notes"
                    isRequired={false}
                    value={getDefaultAnswer(item.id, 3)}
                    onChange={(e) =>
                      handleInputChange(item.id, "noteDetail", e, false)
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        </div>
      
      )}
    </>
  );
}

const ResponseTenantName = ({
  questionData,
  validationErrors,
  selectedScreenId,
  fullProcessData,
  handleInputChange,
}: any) => {
  const { t } = useTranslation();
  const defaultValue =
    fullProcessData.screenDetail?.answerDetail?.[selectedScreenId]?.[
      questionData.id
    ]?.defaultAnswer || "";
  return (
    <div className="w-full h-auto">
      <div className="w-1/2 h-auto">
        <InputFieldWithoutForm
          labelName={t("web.common.tenatname")}
          type="text"
          id="tenantName"
          isRequired={false}
          value={defaultValue}
          onChange={(e) =>
            handleInputChange(questionData.id, "defaultAnswer", e, false)
          }
          error={validationErrors[questionData.id]}
          isReadOnly={true}
          fieldIcon={<ResponseTenantNameIcon />}
        />
      </div>
    </div>
  );
};

const ResponseSiteName = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
}: any) => {
  const { t } = useTranslation();
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  return (
    <div className="w-full h-auto">
      <div className="w-1/2 h-auto">
        <InputFieldWithoutForm
          labelName={t("web.common.sitename")}
          type="text"
          id="siteName"
          isRequired={false}
          value={defaultValue}
          onChange={(e) =>
            handleInputChange(questionData.id, "defaultAnswer", e, false)
          }
          error={validationErrors[questionData.id]}
          isReadOnly={true}
          fieldIcon={<ResponseSiteNameIcon />}
        />
      </div>
    </div>
  );
};

const ResponsePersonName = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
}: any) => {
  const { t } = useTranslation();
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  return (
    <div className="w-full h-auto">
      <div className="w-1/2 h-auto">
        <InputFieldWithoutForm
          labelName={t("web.common.personname")}
          type="text"
          id="personName"
          isRequired={false}
          value={defaultValue}
          onChange={(e) =>
            handleInputChange(questionData.id, "defaultAnswer", e, false)
          }
          error={validationErrors[questionData.id]}
          fieldIcon={<ResponsePersonNameIcon />}
        />
      </div>
    </div>
  );
};

const ResponseLocation = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
}: any) => {
  const { t } = useTranslation();
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  return (
    <div className="w-full h-auto">
      <div className="w-1/2 h-auto">
        <InputFieldWithoutForm
          labelName={t("web.common.location")}
          type="text"
          id="location"
          isRequired={false}
          value={defaultValue}
          onChange={(e) =>
            handleInputChange(questionData.id, "defaultAnswer", e, false)
          }
          error={validationErrors[questionData.id]}
          fieldIcon={<ResponseLocationIcon />}
        />
      </div>
    </div>
  );
};

const ResponseMultipleChoice = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  getDefaultInputValueAnswer,
  handleInputChange,
}: any) => {
  const { t } = useTranslation();
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  const defaultInputValue = getDefaultInputValueAnswer(questionData.id);

  const multipleChoiceOptions = defaultInputValue.map((item: any) => ({
    id: item.id,
    value: item.value,
    label: item.value,
  }));

  let multipleChoiceDefaultValue: any = [];

  if (questionData.subType !== 0 && defaultValue) {
    const defaultValueArray = Array.isArray(defaultValue)
      ? defaultValue
      : [defaultValue];
    multipleChoiceDefaultValue = defaultValueArray.map((item: any) => ({
      id: item.id,
      value: item.value,
      label: item.value,
    }));
  }

  const handleMultiSelectChange = (selectedOptions: any[]) => {
    handleInputChange(questionData.id, "defaultAnswer", selectedOptions, false);
  };

  const handleOptionChange = (option: any) => {
    switch (questionData.subType) {
      case 0:
        handleInputChange(questionData.id, "defaultAnswer", [option], false);
        break;
      case 1:
        handleInputChange(questionData.id, "defaultAnswer", option, true);
        break;
      case 2:
        option = Array.isArray(option) ? option : [option];
        handleInputChange(questionData.id, "defaultAnswer", option, false);
        break;
      default:
        handleInputChange(questionData.id, "defaultAnswer", option, false);
    }
  };
  return (
    <div className="w-full h-auto">
      <div className="w-1/2 h-auto">
        {questionData.subType === 0 ? (
          <RadioBtns
            options={multipleChoiceOptions}
            name="multipleChoiceRadio"
            defaultValue={defaultValue.length > 0 ? defaultValue[0] : undefined}
            onOptionChange={handleOptionChange}
            error={validationErrors[questionData.id]}
          />
        ) : questionData.subType === 1 ? (
          <CheckBoxGroup
            options={multipleChoiceOptions}
            name="multipleChoiceCheckBox"
            defaultValues={multipleChoiceDefaultValue}
            onOptionChange={handleOptionChange}
            error={validationErrors[questionData.id]}
          />
        ) : questionData.subType === 2 ? (
          <SelectInputWithoutForm
            labelName={t("web.common.options")}
            id="multipleChoiceDropdown"
            isRequired={false}
            options={multipleChoiceOptions}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.id}
            getSelection={handleOptionChange}
            defaultValue={multipleChoiceDefaultValue}
            error={validationErrors[questionData.id]}
          />
        ) : questionData.subType === 3 ? (
          <MultiSelectInputWithoutForm
            labelName={t("web.common.options")}
            idName="LabelNames"
            options={multipleChoiceOptions}
            onChange={handleMultiSelectChange}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            defaultValue={multipleChoiceDefaultValue}
            error={validationErrors[questionData.id]}
          />
        ) : questionData.subType === 4 ? (
          <MultiSelectInputWithoutForm
            labelName={t("web.common.options")}
            idName="LabelNames"
            options={multipleChoiceOptions}
            onChange={handleMultiSelectChange}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            defaultValue={multipleChoiceDefaultValue}
            error={validationErrors[questionData.id]}
          />
        ) : null}
      </div>
    </div>
  );
};

const ResponseText = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
}: any) => {
  const { t } = useTranslation();
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  return (
    <div className="w-full h-auto">
      <div className="w-1/2 h-auto">
        {questionData.subType === 0 ? (
          <InputFieldWithoutForm
            labelName={t("web.common.text")}
            type="text"
            id="personName"
            isRequired={false}
            value={defaultValue}
            onChange={(e) =>
              handleInputChange(questionData.id, "defaultAnswer", e, false)
            }
            error={validationErrors[questionData.id]}
            fieldIcon={<ResponseTextIcon />}
          />
        ) : questionData.subType === 1 ? (
          <TextAreaField
            labelName={t("web.common.textarea")}
            type="text"
            id="notes"
            isRequired={false}
            value={defaultValue}
            onChange={(e) =>
              handleInputChange(questionData.id, "defaultAnswer", e, false)
            }
            error={validationErrors[questionData.id]}
          />
        ) : null}
      </div>
    </div>
  );
};

const ResponseDateTime = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
}: any) => {
  const { t } = useTranslation();

  const handleChange = (
    id: string,
    name: string,
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    let formattedDate;
    if (type === "0") {
      if (event.target.value) {
        formattedDate = event.target.value + "T00:00:00Z";
      }
    } else if (type === "1") {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      if (event.target.value) {
        formattedDate = `${year}-${month}-${day}T${event.target.value}:00Z`;
      }
    } else if (type === "2") {
      if (event.target.value) {
        formattedDate = event.target.value + ":00Z";
      }
    } else if (type === "3") {
    }
    handleInputChange(id, name, formattedDate, false);
  };

  const handleDateChange = (id: string, name: string, newRange: any) => {
    if (newRange) {
      const formattedRange = newRange.map((timestamp: any, index: number) => {
        const date = new Date(timestamp);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const day = String(date.getUTCDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}T00:00:00Z`;
        return {
          id: index + 1,
          value: formattedDate,
        };
      });
      handleInputChange(id, name, formattedRange, false);
    } else {
      handleInputChange(id, name, "", false);
    }
  };

  var defaultDate;
  let defaultValue = getDefaultAnswer(questionData.id, 1);

  if (questionData.subType === 0) {
    if (defaultValue.length > 0) {
      defaultDate = defaultValue.split("T")[0];
    }
  } else if (questionData.subType === 1) {
    if (defaultValue.length > 0) {
      defaultDate = defaultValue.split("T")[1]?.slice(0, 5) || "";
    }
  } else if (questionData.subType === 2) {
    if (defaultValue.length > 0) {
      defaultDate = defaultValue.split(":00Z")[0];
    }
  } else if (questionData.subType === 3) {
    if (defaultValue) {
      defaultDate = defaultValue.map((item: any) => {
        if (item.value) {
          const date = new Date(item.value);
          const year = date.getUTCFullYear();
          const month = String(date.getUTCMonth() + 1).padStart(2, "0");
          const day = String(date.getUTCDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        } else {
          return "NULL";
        }
      });
    }
  }

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-8">
        {questionData.subType === 0 ? (
          <div className="w-[25rem] h-auto">
            <input
              type="date"
              id="responseDate"
              className="w-full h-auto text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 bg-io-white dark:bg-io-black-15"
              value={defaultDate}
              onChange={(e) =>
                handleChange(questionData.id, "defaultAnswer", e, "0")
              }
            />
          </div>
        ) : questionData.subType === 1 ? (
          <div className="w-[25rem] h-auto">
            <input
              type="time"
              id="responseTime"
              className="w-full h-auto text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 bg-io-white dark:bg-io-black-15"
              value={defaultDate}
              onChange={(e) =>
                handleChange(questionData.id, "defaultAnswer", e, "1")
              }
            />
          </div>
        ) : questionData.subType === 2 ? (
          <>
            <div className="w-[25rem] h-auto">
              <input
                type="datetime-local"
                id="responseDate"
                className="w-full h-auto text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 bg-io-white dark:bg-io-black-15"
                value={defaultDate}
                onChange={(e) =>
                  handleChange(questionData.id, "defaultAnswer", e, "2")
                }
              />
            </div>
          </>
        ) : questionData.subType === 3 ? (
          <>
            <div className="w-[25rem] h-auto">
              <DatePicker
                value={defaultDate}
                onChange={(newRange: any) =>
                  handleDateChange(questionData.id, "defaultAnswer", newRange)
                }
                range
                rangeHover
                dateSeparator=" to "
                portal
                inputClass="cus-date-range"
                format="YYYY-MM-DD"
              />
            </div>
          </>
        ) : null}
      </div>
      {validationErrors[questionData.id] && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left  top-full left-0">
          {validationErrors[questionData.id]}
        </p>
      )}
    </div>
  );
};

const ResponseSignature = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
  selectedScreenId,
  fullProcessData,
  setAllProcessData,
}: any) => {
  return (
    <div className="w-full h-auto">
      <div className="w-auto h-auto">
        <SignaturePadTest
          selectedScreenId={selectedScreenId}
          questionUniqueId={questionData.id}
          fullProcessData={fullProcessData}
          setAllProcessData={setAllProcessData}
        />
      </div>
      {validationErrors[questionData.id] && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left  top-full left-0">
          {validationErrors[questionData.id]}
        </p>
      )}
    </div>
  );
};

const ResponseScanner = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
}: any) => {
  const { t } = useTranslation();
  const subTypeLabels: { [key: number]: string } = {
    0: t("web.common.scanner.all"),
    1: t("web.common.scanner.barcode"),
    2: t("web.common.scanner.qrcode"),
    3: t("web.common.scanner.ocr"),
  };
  const label =
    subTypeLabels[questionData.subType as keyof typeof subTypeLabels] || "";
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  return (
    <div className="w-full h-auto">
      <div className="w-1/2 h-auto">
        <InputFieldWithoutForm
          labelName={label}
          type="text"
          id={label}
          isRequired={false}
          value={defaultValue}
          onChange={(e) =>
            handleInputChange(questionData.id, "defaultAnswer", e, false)
          }
          error={validationErrors[questionData.id]}
          fieldIcon={<ResponseScannerIcon />}
        />
      </div>
    </div>
  );
};

const ResponseNumber = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
}: any) => {
  const { t } = useTranslation();
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  return (
    <div className="w-full h-auto">
      <div className="w-1/2 h-auto">
        <InputFieldWithoutForm
          labelName={t("web.common.number")}
          type="number"
          id="number"
          isRequired={false}
          value={defaultValue}
          onChange={(e) =>
            handleInputChange(questionData.id, "defaultAnswer", e, false)
          }
          error={validationErrors[questionData.id]}
          fieldIcon={<ResponseNumberIcon />}
        />
      </div>
    </div>
  );
};

const ResponseImage = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
  selectedScreenId,
  fullProcessData,
  setAllProcessData,
  handleImagePreview,
}: any) => {
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  return (
    <div className="w-full h-auto">
      <div className="w-auto h-auto">
        <ImageUpload
          id={"reponseImage"}
          uploadedImageCount={(count: any) => 0}
          selectedScreenId={selectedScreenId}
          questionUniqueId={questionData.id}
          fullProcessData={fullProcessData}
          setAllProcessData={setAllProcessData}
          isEvidence={0}
          handleImagePreview={handleImagePreview}
        />
      </div>
      {validationErrors[questionData.id] && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left  top-full left-0">
          {validationErrors[questionData.id]}
        </p>
      )}
    </div>
  );
};

const ResponseMagicContainer = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  expandPreviewImage,
  handleImagePreview,
}: any) => {
  const defaultValue = getDefaultAnswer(questionData.id, 1) || [];
  const [selectedImageData, setSelectedImageData] = useState<any>({});
  const handleSelection = (data: any) => {
    setSelectedImageData(data);
  };
  useEffect(() => {
    if (defaultValue.length > 0) {
      setSelectedImageData(defaultValue[0]);
    }
  }, [defaultValue]);
  const handleImageError = (event: any) => {
    debugger;
    event.target.src = defaultImage;
  };

  return (
    <div className="w-full h-auto">
      <div className="w-full grid grid-cols-6 max-xl:grid-cols-4 gap-9 max-xl:gap-4">
        {defaultValue.map((value: any, index: number) => (
          <div
            className={`w-full h-auto p-3 border cursor-pointer ${
              value.id === selectedImageData?.id && "border-io-primary"
            } rounded-[0.6rem]`}
            onClick={() => handleSelection(value)}
          >
            <div className="w-full h-[15rem] relative" key={index}>
              <div className="w-full h-full rounded-[0.7rem] overflow-hidden">
                <img
                  className="w-full object-cover object-center"
                  src={value?.url}
                  alt={`Uploaded ${index}`}
                  onError={(e) => handleImageError(e)}
                />
              </div>
              <div className="w-[1.6rem] h-auto absolute top-[3%] right-[3%]">
                {value?.url && (
                  <IconBtn
                    icon={<ExpandPrimaryIcon />}
                    size="w-[1.6rem]"
                    handleClick={() => handleImagePreview(value?.url)}
                  />
                )}
              </div>
            </div>
            <div className="w-full py-[0.4rem]">
              <p className="text-[1.3rem] font-inter font-normal text-io-black">
                {value?.title}
              </p>
            </div>
          </div>
        ))}
      </div>
      <MagicContainerDetail selectedImageData={selectedImageData} />
      {selectedImageData?.inputDetails?.[0]?.labelValue?.[0]?.annotatedUrl && (
        <div className="w-full">
          <p className="text-[1.3rem] font-inter font-normal text-io-gray-66 mb-3">
            Annotation image
          </p>
          <div className="grid grid-cols-6 max-xl:grid-cols-4 gap-9 max-xl:gap-4">
            <div className="w-full h-[15rem] relative">
              <div className="w-full h-full rounded-[0.7rem] overflow-hidden">
                <img
                  className="w-full object-cover object-center"
                  src={
                    selectedImageData?.inputDetails?.[0]?.labelValue?.[0]
                      ?.annotatedUrl ?? defaultImage
                  }
                  alt="Uploaded"
                  onError={(e) => handleImageError(e)}
                />
              </div>
              <div className="w-[1.6rem] h-auto absolute top-[3%] right-[3%]">
                {selectedImageData?.inputDetails?.[0]?.labelValue?.[0]
                  ?.annotatedUrl && (
                  <IconBtn
                    icon={<ExpandPrimaryIcon />}
                    size="w-[1.6rem]"
                    handleClick={() =>
                      handleImagePreview(
                        selectedImageData?.inputDetails?.[0]?.labelValue?.[0]
                          ?.annotatedUrl
                      )
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ResponseAcknowledgement = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
}: any) => {
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  const [isChecked, setIsChecked] = useState(defaultValue);
  const handleCheckboxChange = (event: any) => {
    const { checked } = event.target;
    setIsChecked(checked);
    handleInputChange(questionData.id, "defaultAnswer", checked, false);
  };
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-6">
        <div className="w-[1.8rem] h-auto">
          <input
            type="checkbox"
            name="acknowledgement"
            id="acknowledgement"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </div>
        <p className="text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
          {questionData.title}
        </p>
      </div>
    </div>
  );
};

const ResponseInformation = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
}: any) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-6">
        <div className="w-[1.8rem] h-auto">
          <ProcessMoreInfoIcon />
        </div>
        <p className="text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
          {questionData.title}
        </p>
      </div>
    </div>
  );
};

const ResponseVideo = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
  selectedScreenId,
  fullProcessData,
  setAllProcessData,
}: any) => {
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  const [isPlaying, setIsPlaying] = useState(false);
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  return (
    <div className="w-full h-auto">
      <div className="w-auto h-auto">
        <VideoUpload
          id={"reponseVideo"}
          selectedScreenId={selectedScreenId}
          questionUniqueId={questionData.id}
          fullProcessData={fullProcessData}
          setAllProcessData={setAllProcessData}
        />
      </div>
      {validationErrors[questionData.id] && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left  top-full left-0">
          {validationErrors[questionData.id]}
        </p>
      )}
    </div>
  );
};

const ResponseAudio = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
  selectedScreenId,
  fullProcessData,
  setAllProcessData,
}: any) => {
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  return (
    <div className="w-full h-auto">
      <div className="w-auto h-auto">
        <AudioUpload
          id={"reponseAudio"}
          selectedScreenId={selectedScreenId}
          questionUniqueId={questionData.id}
          fullProcessData={fullProcessData}
          setAllProcessData={setAllProcessData}
        />
      </div>
      {validationErrors[questionData.id] && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left  top-full left-0">
          {validationErrors[questionData.id]}
        </p>
      )}
    </div>
  );
};

const DamageInput = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  expandPreviewImage,
}: any) => {
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  const [selectedImageData, setSelectedImageData] = useState<any>(null);

  // return (
  //   <div className="w-full h-auto">
  //     <div className="w-full h-auto flex justify-start items-center gap-8">
  //       {questionData.subType === 0 ? (
  //         <div className="w-[25rem] h-auto">
  //           <DatePicker range rangeHover portal inputClass="cus-date-range" />
  //         </div>
  //       ) : questionData.subType === 3 ? (
  //         <>
  //           <div className="w-[25rem] h-auto">
  //             <DatePicker range rangeHover portal inputClass="cus-date-range" />
  //           </div>
  //         </>
  //       ) : null}
  //     </div>
  //   </div>
  // );

  return (
    <div className="w-full h-auto">
      <div className="w-auto h-auto">
        <DamagedInputPreview
          id={"damageInput"}
          expandPreviewImage={expandPreviewImage}
          selectedImageData={selectedImageData}
          handleSelection={(data: any) => setSelectedImageData(data)}
        />
      </div>
    </div>
  );
};

const ResponseToggle = ({
  questionData,
  validationErrors,
  getDefaultAnswer,
  handleInputChange,
}: any) => {
  const defaultValue = getDefaultAnswer(questionData.id, 1);
  const [isChecked, setIsChecked] = useState(defaultValue == 1 ? true : false);
  const handleToggleChange = (newState: any) => {
    const value = newState === true ? 1 : 0;
    setIsChecked(newState);
    handleInputChange(questionData.id, "defaultAnswer", value, false);
  };
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-6">
        <div className="w-[1.8rem] h-auto">
          <ToggleButtonWithoutForm
            id="toggle"
            size="large"
            labelPosition="right"
            label={"Evidence"}
            isButtonChecked={isChecked}
            getIsChecked={(newState) => handleToggleChange(newState)}
            error={validationErrors[questionData.id]}
          />
        </div>
        {validationErrors[questionData.id] && (
          <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
            {validationErrors[questionData.id]}
          </p>
        )}
      </div>
    </div>
  );
};

export default QuestionCard;
