import ModalPageWrapper from "../../global-layout/modal-page-wrapper";

function PageLoader() {
  return (
    <ModalPageWrapper>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-auto h-auto">
          <div className="loader mx-auto"></div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default PageLoader;
