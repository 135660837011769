import React, { useEffect, useRef, useState } from "react";
import { ImageDeleteIcon, UploadAudioIcon } from "../../svg";
import uploadFile from "../../../services/uploadFile";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

function AudioUpload({
  id,
  uploadedImageCount,
  selectedScreenId,
  questionUniqueId,
  fullProcessData,
  setAllProcessData,
}: any) {
  const { t } = useTranslation();
  const generateGuid = () => uuidv4();
  const [uploadedAudios, setUploadedAudios] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const uploadFiles = useRef<HTMLInputElement>(null);

  const handleFileInputChange = async (event: any) => {
    setIsLoading(true);
    if (
      uploadFiles.current &&
      uploadFiles.current.files &&
      uploadFiles.current.files.length > 0
    ) {
      const files = uploadFiles.current.files;

      let newAudios: any[] =
        fullProcessData?.screenDetail?.answerDetail?.[selectedScreenId]?.[
          questionUniqueId
        ].defaultAnswer || [];
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.name;
        const fileType = fileName.split(".").pop();
        const extension = fileType ? fileType.toLowerCase() : "";
        // if (!newAudios.some((image) => image.name === file.name)) {
        uploadPromises.push(
          uploadFile(file, extension).then(({ response, fileUrl }) => {
            if (response.success) {
              newAudios.push({
                id: generateGuid(),
                url: response.data.fileUrl,
              });
            }
          })
        );
        // }
      }
      try {
        await Promise.all(uploadPromises);
        setUploadedAudios(newAudios);
        setIsLoading(false);
        handleAllAudioData(newAudios);
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    }
  };

  const handleAllAudioData = (newAudios: any[]) => {
    if (fullProcessData.screenDetail.answerDetail[selectedScreenId]) {
      if (
        fullProcessData?.screenDetail?.answerDetail?.[selectedScreenId]?.[
          questionUniqueId
        ]
      ) {
        fullProcessData.screenDetail.answerDetail[selectedScreenId][
          questionUniqueId
        ].defaultAnswer = newAudios;
      }
    }
    setAllProcessData(fullProcessData);
  };

  const deleteSelectedFiles = (index: number) => {
    const updatedAudios = [
      ...fullProcessData?.screenDetail?.answerDetail?.[selectedScreenId]?.[
        questionUniqueId
      ].defaultAnswer,
    ];
    updatedAudios.splice(index, 1);
    setUploadedAudios(updatedAudios);
    handleAllAudioData(updatedAudios);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [fullProcessData.screenDetail.answerDetail[selectedScreenId]]);

  return (
    <div className="w-full h-auto">
      <div className="grid grid-cols-6 max-xl:grid-cols-4 gap-9 max-xl:gap-4">
        <div className="w-auto h-auto">
          <input
            type="file"
            id={`uploadAudio${id}`}
            multiple={true}
            accept="Audio/*"
            onChange={handleFileInputChange}
            ref={uploadFiles}
            className="hidden w-0 h-0"
          />
          <label htmlFor={`uploadAudio${id}`} className="w-auto h-auto">
            <div className="w-auto h-auto py-[3.2rem] flex justify-center items-center rounded-[0.7rem] border border-dashed border-io-primary">
              <div className="w-auto h-auto max-xl:w-[4rem]">
                <UploadAudioIcon />
              </div>
            </div>
          </label>
        </div>
        {fullProcessData.screenDetail.answerDetail[selectedScreenId]?.[
          questionUniqueId
        ]?.defaultAnswer?.length > 0
          ? fullProcessData.screenDetail.answerDetail[selectedScreenId][
              questionUniqueId
            ].defaultAnswer.map((audio: any, index: any) => (
              <div className="w-full h-full relative" key={index}>
                <audio controls className="w-full h-full rounded-[0.7rem]">
                  <source src={audio?.url} />
                  {t("web.assets.common.audio")}
                </audio>
                <div
                  className="w-[1.8rem] h-auto absolute bottom-[3%] right-[3%] cursor-pointer"
                  onClick={() => deleteSelectedFiles(index)}
                >
                  <ImageDeleteIcon />
                </div>
              </div>
            ))
          : ""}
        {isLoading && <Skeleton width={140} height={100} borderRadius={5} />}
      </div>
    </div>
  );
}

export default AudioUpload;
