import { useState, useEffect } from "react";

interface Option {
  id: string;
  label: string;
}

interface CheckBoxGroupProps {
  options: Option[];
  name: string;
  defaultValues?: Option[];
  error?: any;
  onOptionChange?: (selectedOption: Option) => void;
}

function CheckBoxGroup({
  options,
  name,
  error,
  defaultValues = [],
  onOptionChange,
}: CheckBoxGroupProps) {
  const [selectedOptions, setSelectedOptions] =
    useState<Option[]>(defaultValues);

  useEffect(() => {
    setSelectedOptions(defaultValues);
  }, [defaultValues]);

  const handleChange = (selectedOption: Option) => {
    const isSelected = selectedOptions.some(
      (option) => option.id === selectedOption.id
    );
    const newSelectedOptions = isSelected
      ? selectedOptions.filter((option) => option.id !== selectedOption.id)
      : [...selectedOptions, selectedOption];

    setSelectedOptions(newSelectedOptions);
    onOptionChange?.(selectedOption);
  };

  return (
    <>
      <div className="w-full h-auto flex justify-start items-center gap-8 flex-wrap">
        {options.map((option) => (
          <div
            key={option.id}
            className="w-auto flex justify-start items-center gap-4"
          >
            <input
              type="checkbox"
              name={name}
              id={option.id}
              className="accent-io-primary"
              checked={selectedOptions.some(
                (selectedOption) => selectedOption.id === option.id
              )}
              onChange={() => handleChange(option)}
            />
            <label
              htmlFor={option.id}
              className="text-[1.4rem] font-inter font-normal text-io-black dark:text-io-white"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {error && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left  top-full left-0">
          {error}
        </p>
      )}
    </>
  );
}

export default CheckBoxGroup;
