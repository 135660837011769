function MagicContainerDetail({ selectedImageData }: any) {
  const statusColor = [
    { id: 1, textColor: "#0D8A47", fillColor: "#E6FFEA" },
    { id: 2, textColor: "#f44336", fillColor: "#FFE3E3" },
    { id: 3, textColor: "#FFB147", fillColor: "#FFF1E1" },
    { id: 4, textColor: "#FFB147", fillColor: "#FFF1E1" },
    { id: 5, textColor: "#0078D4", fillColor: "#EAF6FF" },
  ];
  const getStatusColor = (resultId: any) => {
    return (
      statusColor.find((status) => status.id === resultId) || {
        textColor: "#000",
        fillColor: "#fff",
      }
    );
  };
  const { textColor, fillColor } = getStatusColor(selectedImageData?.resultId);

  return (
    <div className="w-full h-auto mt-5 pb-5 flex justify-start items-center flex-wrap gap-x-24 gap-y-12">
      <div className="w-auto">
        <p className="text-[1.3rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
          Status
        </p>
        <p
          className="text-[1.4rem] font-inter font-medium"
          style={{ color: textColor }}
        >
          <span
            className="text-[1.4rem] font-inter font-medium text-io-black dark:text-io-white"
            style={{ backgroundColor: fillColor }}
          ></span>
          {selectedImageData?.resultText}
        </p>
      </div>
      {selectedImageData?.inputDetails?.length > 0 &&
        selectedImageData.inputDetails.map((value: any, index: number) => (
          <div className="w-auto" key={index}>
            <p className="text-[1.3rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
              {value?.labelKey}
            </p>
            <p className="text-[1.4rem] font-inter font-medium text-io-black dark:text-io-white">
              {value?.labelValue}
            </p>
          </div>
        ))}
    </div>
  );
}
export default MagicContainerDetail;
