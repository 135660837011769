import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { useEffect, useMemo, useState } from "react";
import IconBtn from "../../form/icon-btn";
import {
  CloseImageEditor,
  ColorPickerTool,
  CropTool,
  MoveTool,
  PageModalCloseIcon,
  PenTool,
  RectTool,
  RedoTool,
  ResetTool,
  UndoTool,
} from "../../svg";
import { fabric } from "fabric";
import { v4 as uuidv4 } from "uuid";
import deteleIcon from "../../../assets/images/editorDeteleIcon.svg";
import { TextBtn } from "../../form/text-btn";
import { InputFieldWithoutForm, TextAreaField } from "../../form/input-fields";
import { ToggleButtonWithoutForm } from "../../form/toggle-btn";
import { SelectInputWithoutForm } from "../../form/select-input";
import { useTranslation } from "react-i18next";

interface ImageEditorProps {
  imageSource: any;
}

declare module "fabric" {
  interface Canvas {
    __eventListeners: any;
  }
}
declare module "fabric" {
  namespace fabric {
    interface Object {
      uniqueId?: any;
      isDeleted?: any;
    }
  }
}

function ImageEditor({ imageSource }: ImageEditorProps) {
  const { t } = useTranslation();
  const generateGuid = () => uuidv4();
  const { editor, onReady, selectedObjects } = useFabricJSEditor();
  const [selectedImage, setSelectedImage] = useState<boolean>(false);
  const [rectImage, setRectImage] = useState<boolean>(false);
  const [drawImage, setDrawImage] = useState<boolean>(false);
  const [moveImage, setMoveImage] = useState<boolean>(false);
  const [currentTool, setCurrentTool] = useState<string>("rect");
  const [strokeLevel, setStrokeLevel] = useState<Number>(10);
  const [imgSrc, setImgSrc] = useState<string>("");
  const [color, setColor] = useState("#FCE049");
  const [cropImage, setCropImage] = useState<boolean>(false);
  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false);
  const history: any[] = [];

  const multipleChoiceOptions = [{ label: "Option 1", value: "option1" }];

  const renderImage = (base64URL: string) => {
    if (!editor || !fabric) {
      console.log("renderImage: editor or fabric is not defined");
      return;
    }
    setImgSrc(base64URL);
    fabric.Image.fromURL(base64URL, function (img) {
      editor.canvas.setBackgroundImage(
        img,
        editor.canvas.renderAll.bind(editor.canvas)
      );
      if (Number(img.width) >= 100 || Number(img.height) >= 100) {
        const zoomLevel = Math.min(
          Number(editor.canvas.width) / Number(img.width),
          Number(editor.canvas.height) / Number(img.height)
        );
        const centerX = Number(editor.canvas.width) / 20;
        const centerY = Number(editor.canvas.height) / 20;
        editor.canvas.zoomToPoint({ x: centerX, y: centerY }, zoomLevel);
      } else {
        editor.canvas.zoomToPoint({ x: 1, y: 1 }, 1.4);
      }
    });
  };

  useEffect(() => {
    if (!editor || !fabric) {
      console.log("renderImage: editor or fabric is not defined");
      return;
    }
    if (imageSource) {
      setSelectedImage(true);
      renderImage(imageSource);
    } else {
      setSelectedImage(false);
    }
  }, [imageSource, editor, fabric]);

  useEffect(() => {
    if (cropImage) setCurrentTool("crop");
    if (rectImage) setCurrentTool("rect");
    if (drawImage) setCurrentTool("draw");
    if (moveImage) setCurrentTool("move");

    if (!cropImage && !rectImage && !drawImage && !moveImage)
      setCurrentTool(" ");
    if (!editor) {
      return;
    }
    if (!cropImage) {
      if (drawImage) {
        editor.canvas.isDrawingMode = true;
      }
    }
  }, [cropImage, rectImage, drawImage, moveImage]);

  const renderSidebar = useMemo(() => {
    if (selectedImage) {
      if (rectImage || drawImage) {
        return (
          <div
            className={`sidebar ${
              selectedImage
                ? "visible pointer-events-auto"
                : " invisible pointer-events-none"
            }`}
          >
            <input
              type="range"
              onChange={(e: any) => setStrokeLevel(e.target.value)}
              value={strokeLevel.toString()}
              min={1}
              max={100}
              id="strokelevel"
              title="Stroke Size"
            />
            <label className="font-bold" htmlFor="strokelevel">
              {strokeLevel.toString()}
            </label>
          </div>
        );
      }
    }
  }, [rectImage, selectedImage, strokeLevel, drawImage]);

  const pickColor = (e: any) => {
    setColor(e.target.value);
  };

  const toggleRect = () => {
    setRectImage(true);
    setCropImage(false);
    setDrawImage(false);
    setMoveImage(false);
  };

  const toggleMove = () => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas.isDrawingMode = false;
    setMoveImage(true);
    setDrawImage(false);
    setRectImage(false);
    setCropImage(false);
  };

  const toggleDraw = () => {
    if (!editor || !fabric) {
      return;
    }
    if (
      !editor ||
      !editor.canvas.width ||
      !editor.canvas.height ||
      !editor.canvas.backgroundImage
    ) {
      return;
    }
    editor.canvas.freeDrawingBrush.width = Number(strokeLevel);
    editor.canvas.freeDrawingBrush.color = color;
    editor.canvas.isDrawingMode = true;
    // editor.canvas.on("path:created", (event) => {
    //   const e = event as fabric.IEvent<MouseEvent> & { path: fabric.Path };
    // //   e.path.uniqueId = uuidv4();
    // });
    setDrawImage(true);
    setRectImage(false);
    setCropImage(false);
    setMoveImage(false);
  };

  const toggleCrop = () => {
    setCropImage(!cropImage);
    setRectImage(false);
    setDrawImage(false);
    setMoveImage(false);
  };

  const undo = () => {
    if (!editor || !fabric) {
      return;
    }
    if (editor.canvas._objects.length > 0) {
      history.push(editor.canvas._objects.pop());
    }
    editor.canvas.renderAll();
    setCropImage(false);
    setRectImage(false);
    setDrawImage(false);
    setMoveImage(false);
    editor.canvas.isDrawingMode = false;
  };

  const redo = () => {
    if (!editor || !fabric) {
      return;
    }
    if (history.length > 0) {
      editor.canvas.add(history.pop());
      setCropImage(false);
      setRectImage(false);
      setDrawImage(false);
      setMoveImage(false);
      editor.canvas.isDrawingMode = false;
    }
  };

  const resetAll = () => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas._objects.splice(0, editor.canvas._objects.length);
    history.splice(0, history.length);
    editor.canvas.renderAll();
    setCropImage(false);
    setRectImage(false);
    setDrawImage(false);
    setMoveImage(false);
    editor.canvas.isDrawingMode = false;

    editor.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    fabric.Image.fromURL(imgSrc, function (img) {
      editor.canvas.setBackgroundImage(
        img,
        editor.canvas.renderAll.bind(editor.canvas)
      );
      if (Number(img.width) >= 100 || Number(img.height) >= 100) {
        const zoomLevel = Math.min(
          Number(editor.canvas.width) / Number(img.width),
          Number(editor.canvas.height) / Number(img.height)
        );
        const centerX = Number(editor.canvas.width) / 20;
        const centerY = Number(editor.canvas.height) / 20;
        editor.canvas.zoomToPoint({ x: centerX, y: centerY }, zoomLevel);
      } else {
        editor.canvas.zoomToPoint({ x: 1, y: 1 }, 1.4);
      }
    });
  };

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    if (
      !editor ||
      !editor.canvas.width ||
      !editor.canvas.height ||
      !editor.canvas.backgroundImage
    ) {
      return;
    }
    editor.canvas.freeDrawingBrush.width = Number(strokeLevel);
    const obj: any = editor.canvas.getActiveObjects();
    if (obj) obj[0]?.set({ strokeWidth: strokeLevel });
    editor.canvas.isDrawingMode = true;
  }, [editor, strokeLevel]);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    let rectangle: fabric.Object, isDown: boolean, origX: number, origY: number;
    if (rectImage) {
      editor.canvas.on("mouse:down", function (o: any) {
        if (o.target == null) {
          editor.canvas.isDrawingMode = false;
          var pointer = editor.canvas.getPointer(o.e);
          isDown = true;
          origX = pointer.x;
          origY = pointer.y;
          rectangle = new fabric.Rect({
            left: origX,
            top: origY,
            fill: "transparent",
            stroke: color,
            strokeWidth: Number(strokeLevel),
            width: 0,
            height: 0,
          });
          (rectangle as any).uniqueId = generateGuid();
          editor.canvas.add(rectangle);
        }
      });
      editor.canvas.on("mouse:move", function (o: any) {
        editor.canvas.isDrawingMode = false;
        if (!isDown) return;

        var pointer = editor.canvas.getPointer(o.e);
        if (origX > pointer.x) {
          rectangle.set({ left: Math.abs(pointer.x) });
        }
        if (origY > pointer.y) {
          rectangle.set({ top: Math.abs(pointer.y) });
        }
        rectangle.set({ width: Math.abs(origX - pointer.x) });
        rectangle.set({ height: Math.abs(origY - pointer.y) });
        editor.canvas.renderAll();
      });
      editor.canvas.on("mouse:up", function (o: any) {
        editor.canvas.isDrawingMode = false;
        isDown = false;
        if (o.target) {
          if (o.target.width === 0) {
            editor.canvas.remove(o.target);
            editor.canvas.renderAll();
          }
        }
      });
    }
    return () => {
      editor.canvas.isDrawingMode = false;
      editor.canvas.off("mouse:down");
      editor.canvas.off("mouse:move");
      editor.canvas.off("mouse:up");
      editor.canvas.off("mouse:over");
    };
  }, [editor, rectImage, strokeLevel, color]);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    fabric.Object.prototype.set({
      transparentCorners: false,
      cornerColor: "#0078d4",
      cornerStrokeColor: "white",
      borderColor: "#0078d4",
      cornerSize: 12,
      borderOpacityWhenMoving: 0.5,
      padding: 10,
      cornerStyle: "circle",
      borderDashArray: [5, 5],
      uniqueId: generateGuid(),
      isDeleted: 0,
    });
  }, [editor]);

  const resetAllHandle = () => {
    if (!editor || !fabric) {
      return;
    }
    resetAll();
    setCurrentTool("");
    setColor("#FCE049");
    setStrokeLevel(10);
    setCropImage(false);
    setRectImage(false);
    setDrawImage(false);
    setMoveImage(false);
    setSelectedImage(false);
    editor.canvas.isDrawingMode = false;
    editor.canvas.renderAndReset();
    editor.deleteAll();
    editor.canvas.remove();
    editor.canvas.clear();
    editor.canvas.discardActiveObject();
    editor.canvas.renderAll();
    editor.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  };
  useEffect(() => {
    const img = new Image();
    img.src = deteleIcon;
    function deleteObject(eventData: MouseEvent, transform: any): boolean {
      const target = transform.target;
      const canvas = target.canvas;
      if (canvas) {
        canvas.remove(target);
        canvas.requestRenderAll();
        return true;
      }
      return false;
    }
    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 0,
      offsetX: 20,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: (ctx, left, top, styleOverride, fabricObject: any) => {
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -20 / 2, -20 / 2, 20, 20);
        ctx.restore();
      },
    });
  }, [editor]);

  return (
    <div className="w-full h-auto">
      {/* <div className="w-full h-auto flex justify-center items-center">
          <div className="w-1/2 h-auto mx-auto p-6 bg-[#F4FAFF] dark:bg-io-black-1c mb-5">
          <div className="w-full h-auto flex justify-around items-center gap-[8%]">
            <IconBtn
              size="w-[1.8rem]"
              icon={<RectTool />}
              handleClick={() => toggleRect()}
              disabled={cropImage || !selectedImage}
              title="Rectangle"
              isActive={currentTool === "rect"}
            />
            <IconBtn
              size="w-[1.8rem]"
              icon={<MoveTool />}
              handleClick={() => toggleMove()}
              disabled={cropImage || !selectedImage}
              title="Selection"
              isActive={currentTool === "move"}
            />
            <IconBtn
              size="w-[1.8rem]"
              icon={<PenTool />}
              handleClick={() => toggleDraw()}
              disabled={cropImage || !selectedImage}
              title="Draw"
              isActive={currentTool === "draw"}
            />
           
            
            <label
              htmlFor="colorPicker"
              className="w-[2.5rem] h-[2.5rem] relative"
            >
              <input
                type="color"
                id="colorPicker"
                className="w-0 h-0 invisible"
                value={color}
                // onChange={(e) => setColor(e.target.value)}
                onChange={(e) => pickColor(e)}
              />
              <div
                title="Color Picker"
                className="pointer-events-none absolute top-0 left-0"
              >
                <IconBtn size="w-[2.5rem]" icon={<ColorPickerTool />} />
              </div>
            </label>
          </div>
        </div>
        <div className="w-1/2 h-full relative">
          {renderSidebar}
        </div>
          </div> */}
      <div className="w-full h-[50rem] relative bg-[#F4FAFF] dark:bg-io-black-1a">
        <div
          className="w-full h-full rounded-[8px] overflow-hidden"
          onClick={() => setShowMoreOptions(false)}
        >
          <FabricJSCanvas className="w-full h-full" onReady={onReady} />
        </div>
        {/* <div className="w-auto h-auto absolute top-2 right-2">
          <IconBtn
            size="w-[2rem]"
            icon={<CloseImageEditor />}
            handleClick={resetAllHandle}
          />
        </div> */}
      </div>
      {/* {showMoreOptions && (
        <div className="w-[35%] h-full bg-io-white dark:bg-io-black-15 absolute top-0 right-0 z-[2] rounded-[2.2rem] shadow-[rgba(0,0,0,0.02)_0px_1px_3px_0px,rgba(27,31,35,0.15)_0px_0px_0px_1px]">
          <div className="w-full h-full flex flex-col">
            <div className="w-full h-auto border-b border-io-gray-c8">
              <div className="w-full h-auto px-[8%] pt-[5%] pb-[3%] flex justify-between items-center">
                <p className="text-[2.2rem] font-inter text-io-black font-semibold dark:text-io-white">
                  Label 1
                </p>
                <IconBtn
                  size="w-[2rem]"
                  icon={<PageModalCloseIcon />}
                  handleClick={() => setShowMoreOptions(false)}
                />
              </div>
            </div>
            <div className="w-full h-auto p-[8%] flex-1 basis-0 overflow-y-auto scrollbar-small">
              <div className="grid grid-cols-2 gap-4 mb-[2rem]">
                <div className="w-full">
                  <InputFieldWithoutForm
                    labelName={t("web.common.partcode")}
                    type="text"
                    id="partCode"
                    isRequired={false}
                  />
                </div>
                <div className="w-full">
                  <InputFieldWithoutForm
                    labelName={t("web.common.damagecode")}
                    type="text"
                    id="damageCode"
                    isRequired={false}
                  />
                </div>
                <div className="w-full">
                  <InputFieldWithoutForm
                    labelName={t("web.common.severitycode")}
                    type="text"
                    id="severityCode"
                    isRequired={false}
                  />
                </div>
              </div>
              <div className="w-full mb-[2rem]">
                <SelectInputWithoutForm
                  labelName={t("web.common.part")}
                  id="multipleChoiceDropdown"
                  isRequired={false}
                  options={multipleChoiceOptions}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.id}
                  getSelection={() => console.log("asdasd")}
                  // defaultValue={defaultValue}
                />
              </div>
              <div className="w-full text-left mb-[2rem]">
                <SelectInputWithoutForm
                  labelName={t("web.common.damage")}
                  id="multipleChoiceDropdown"
                  isRequired={false}
                  options={multipleChoiceOptions}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.id}
                  getSelection={() => console.log("asdasd")}
                  // defaultValue={defaultValue}
                />
              </div>
              <div className="w-full mb-[2rem]">
                <SelectInputWithoutForm
                  labelName={t("web.common.severity")}
                  id="multipleChoiceDropdown"
                  isRequired={false}
                  options={multipleChoiceOptions}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.id}
                  getSelection={() => console.log("asdasd")}
                  // defaultValue={defaultValue}
                />
              </div>
              <div className="w-full mb-[2rem]">
                <TextAreaField
                  labelName={t("web.common.comments")}
                  type="text"
                  id="comments"
                  isRequired={false}
                />
              </div>
              <div className="w-auto flex justify-start ">
                <ToggleButtonWithoutForm
                  id={"toggle"}
                  label={t("web.common.repairreq")}
                  size="large"
                  labelPosition="left"
                  getIsChecked={(isChecked) => {}}
                  defaultChecked={false}
                />
              </div>
            </div>
            <div className="w-full h-auto flex justify-start items-center gap-4 px-[8%] py-[6%]">
              <TextBtn
                label={t("web.common.cancel")}
                btnSize="small"
                btnStyle="fill-secondary"
                btnType="button"
                handleClick={() => setShowMoreOptions(false)}
              />
              <TextBtn
                label={t("web.common.submit")}
                btnSize="small"
                btnStyle="fill-primary"
                btnType="submit"
              />
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}
export default ImageEditor;
