import { useEffect, useRef, useState } from "react";
import { ExpandIcon, ImageDeleteIcon, UploadImageIcon } from "../../svg";

interface DamagedInputPreviewProps {
  id: any;
  expandPreviewImage: (status: boolean, data: any) => void;
  handleSelection: (data: any) => void;
  selectedImageData: any;
}

function DamagedInputPreview({
  id,
  expandPreviewImage,
  handleSelection,
  selectedImageData,
}: DamagedInputPreviewProps) {
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);
  const uploadFiles = useRef<HTMLInputElement>(null);

  const handleFileInputChange = (event: any) => {
    if (
      uploadFiles.current &&
      uploadFiles.current.files &&
      uploadFiles.current.files.length > 0
    ) {
      const files = uploadFiles.current.files;
      const newImages: any[] = [...uploadedImages];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!newImages.some((image) => image.name === file.name)) {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (e.target && e.target.result) {
              newImages.push({
                name: file.name,
                url: e.target.result.toString(),
              });
              setUploadedImages([...newImages]);
            }
          };
          reader.readAsDataURL(file);
        }
      }
    }
  };

  const deleteSelectedFiles = (e: any, index: any) => {
    e.preventDefault();
    const updatedImages = uploadedImages.filter((image, i) => i !== index);
    setUploadedImages(updatedImages);
  };

  const expandImage = (e: any, data: any) => {
    e.preventDefault();
    expandPreviewImage(true, data);
  };

  return (
    <div className="w-full h-auto">
      <div className="grid grid-cols-6 max-xl:grid-cols-4 gap-9 max-xl:gap-4">
        <div className="w-auto h-auto">
          <input
            type="file"
            id={`uploadImage${id}`}
            accept="Image/*"
            multiple={true}
            onChange={handleFileInputChange}
            ref={uploadFiles}
            className="hidden w-0 h-0"
          />
          <label htmlFor={`uploadImage${id}`} className="w-auto h-auto">
            <div className="w-auto h-auto py-[3.2rem] flex justify-center items-center rounded-[0.7rem] border border-dashed border-io-primary">
              <div className="w-auto h-auto max-xl:w-[4rem]">
                <UploadImageIcon />
              </div>
            </div>
          </label>
        </div>
        {uploadedImages.map((data, index) => (
          <div
            key={index}
            className={`w-full h-auto relative p-2 border rounded-[0.6rem] ${
              selectedImageData?.name === data.name
                ? "border-io-primary"
                : "border-transparent"
            }`}
            onClick={() => handleSelection(data)}
          >
            <div className="w-full h-full rounded-[0.7rem] overflow-hidden ">
              <img
                className="w-full object-cover object-center"
                src={data.url}
                alt={`Uploaded  ${data.url}`}
              />
            </div>
            <div
              className="w-[1.8rem] h-auto absolute bottom-[8%] right-[5%] cursor-pointer"
              onClick={(e: any) => deleteSelectedFiles(e, index)}
            >
              <ImageDeleteIcon />
            </div>
            <div
              className="w-[1.8rem] h-auto absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer"
              onClick={(e: any) => expandImage(e, data)}
            >
              <ExpandIcon />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default DamagedInputPreview;
