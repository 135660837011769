import Skeleton from "react-loading-skeleton";
import defaultImage from "../../../assets/images/default.png";

import React from "react";

function ProcessDetailHeader({
  allGetStarted,
  setAllGetStarted,
  isGetStartedAvailable,
  title,
  isLoading,
}: any) {
  const handleImageError = (event: any) => {
    event.target.src = defaultImage;
  };
  return (
    <div className="w-full h-auto mb-[4rem] pt-[2rem]">
      <div className="w-full h-auto flex justify-between items-center gap-4">
        <div className="w-4/5 h-auto">
          <div className="w-full h-auto flex justify-start items-center gap-6">
            {isLoading ? (
              <Skeleton width={60} height={60} borderRadius={50} />
            ) : (
              allGetStarted?.[0]?.icon && (
                <div className="w-[6rem] h-auto">
                  <img
                    src={allGetStarted[0].icon}
                    alt="PreviewImage"
                    onError={(e) => handleImageError(e)}
                  />
                </div>
              )
            )}
            <div className="flex-1 h-auto">
              <div className="w-full h-auto flex justify-start items-center gap-3">
                {isLoading ? (
                  <Skeleton width={300} height={25} borderRadius={3} />
                ) : (
                  <>
                    <h5 className="text-[2rem] font-inter font-semibold uppercase text-io-black dark:text-io-white">
                      {title || ""}
                    </h5>
                    <div
                      className={`w-auto inline-block rounded-[0.2rem] px-2 py-1 bg-opacity-10 
                      bg-io-green text-io-green}`}
                      style={{ backgroundColor: allGetStarted?.[0]?.fillColor }}
                    >
                      <p
                        className="text-[1.6rem] font-inter font-normal text-inherit"
                        style={{ color: allGetStarted?.[0]?.textColor }}
                      >
                        {allGetStarted?.[0]?.statusText || ""}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="w-full h-auto flex justify-start items-start divide-x divide-io-gray-c8">
                {isLoading ? (
                  <Skeleton width={500} height={25} borderRadius={3} />
                ) : (
                  <>
                    {isGetStartedAvailable === 1 &&
                      allGetStarted.slice(1).map((item: any, index: any) => {
                        if (!item.labelValue) {
                          return null;
                        }
                        return (
                          <React.Fragment key={index}>
                            <p className="text-[1.4rem] font-inter font-medium text-io-gray-66 leading-none px-4 dark:text-io-black-d1">
                              {item.labelValue}
                            </p>
                          </React.Fragment>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-1/5 h-auto">
          <div className="w-full h-auto flex justify-end items-center gap-[4rem]">
            {isLoading ? (
              <Skeleton width={300} height={25} borderRadius={3} />
            ) : (
              <>
                <div className="w-auto">
                  <IconTextBtn
                    type="button"
                    label="More Info"
                    btnSize="large"
                    icon={<ProcessMoreInfoIcon />}
                    handleClick={() => console.log("asdasd")}
                  />
                </div>
                <div className="w-auto">
                  <IconTextBtn
                    type="button"
                    label="Export"
                    btnSize="large"
                    icon={<ExportIcon />}
                    handleClick={() => console.log("asdasd")}
                  />
                </div>
              </>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default ProcessDetailHeader;
