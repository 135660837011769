import { useEffect, useState } from "react";
import PageHeader from "../../global-layout/page-header";
import OptionOneMain from "../option-one-main";
import { processService } from "../../../services/InspectionOneServices";
import { useParams } from "react-router-dom";
import { ToastError, ToastSuccess, ToastWarning } from "../../UI/toast";
import ToastifyMessage from "../../../lib/toastify";
import PageLoader from "../../UI/page-loader";
import { useTranslation } from "react-i18next";

function OptionOneWrapper() {
  const { t } = useTranslation();
  const { authToken } = useParams();
  const { ToastifyHandle } = ToastifyMessage();
  const { siteUniqueId, languageCode } = useParams();
  const [allProcessData, setAllProcessData] = useState<any>();
  const [allGetStarted, setAllGetStarted] = useState<any>();
  const [overridden, setoverridden] = useState<any>();
  const [authTokenDetails, setAuthTokenDetails] = useState<any>();
  const [isGetStartedAvailable, setIsGetStartedAvailable] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(0);
  const processLanuage = t("web.common.process");
  const requiredFieldValidation = t("web.common.required");
  const videoFieldValidation = t("web.common.videoFieldValidation");
  const audioFieldValidation = t("web.common.audioFieldValidation");
  const imageFieldValidation = t("web.common.imageFieldValidation");

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const initialFetch = async () => {
    setPageLoader(true);
    setIsLoading(true);
    try {
      const requestJson = {
        authToken: authToken,
      };
      const response = await processService.ProcessRegistryWebFetch(
        requestJson,
        siteUniqueId,
        languageCode
      );
      const responseData = response.data;
      if (response.success) {
        setAllProcessData(responseData);
        sessionStorage.setItem(
          "image",
          responseData.authTokenDetails[0].userProfileImage || ""
        );
        setIsGetStartedAvailable(
          responseData.screenDetail.isGetStartedAvailable
        );
        setAllGetStarted(responseData.getStartedData);
        if (responseData.authTokenDetails[0].userType) {
          setIsViewOnly(
            responseData.authTokenDetails[0].userType === "External"
              ? 1
              : responseData.screenDetail.isOnlyMagicContainer
          );
        }
        setoverridden(responseData.isOverridden);
        setAuthTokenDetails(responseData.authTokenDetails);
        setIsLoading(false);
        setPageLoader(false);
      } else {
        if (response.message === "AuthToken has expired.") {
          handleError(processLanuage, t("web.common.authtoken"));
        } else {
          handleError(processLanuage, t("web.common.somethingwentwrong"));
        }
        setIsLoading(false);
        setPageLoader(false);
        console.error("Error fetching data:");
      }
    } catch (error) {
      handleError(processLanuage, t("web.common.somethingwentwrong"));
      setIsLoading(false);
      setPageLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    initialFetch();
    sessionStorage.setItem("language", languageCode || "en");
  }, []);

  useEffect(() => {}, [allProcessData]);

  useEffect(() => {}, [allGetStarted]);

  const validateField = (question: any, answer: any) => {
    let errors: any = {};
    if (question.isMandatory) {
      if (question.type === 9 && question.subType === 3) {
        console.log("answer.defaultAnswer", answer.defaultAnswer);
        if (!answer.defaultAnswer || answer.defaultAnswer.length === 0) {
          errors[question.id] = requiredFieldValidation;
        }
      } else if (question.type === 12) {
        if (!answer.defaultAnswer || answer.defaultAnswer.length === 0) {
          errors[question.id] = requiredFieldValidation;
        }
      } else {
        if (!answer.defaultAnswer || answer.defaultAnswer.length === 0) {
          errors[question.id] = requiredFieldValidation;
        }
      }
      console.log();
    }
    return errors;
  };

  const validateAllFields = (mainScreenDetails: any, answerDetail: any) => {
    let errors: any = {};

    mainScreenDetails.forEach((screen: any) => {
      const questionDetails = screen.questionDetails;
      let screenErrors = {};

      questionDetails.forEach((question: any) => {
        const fieldErrors = validateField(
          question,
          answerDetail[screen.id]?.[question.id]
        );
        screenErrors = { ...screenErrors, ...fieldErrors };
      });

      if (Object.keys(screenErrors).length > 0) {
        errors[screen.id] = screenErrors;
      }
    });

    return errors;
  };

  const handleSubmit = async () => {
    const mainScreenDetails = allProcessData?.screenDetail?.mainScreenDetails;
    const answerDetail = allProcessData?.screenDetail?.answerDetail;
    const errors = validateAllFields(mainScreenDetails, answerDetail);
    console.log("errors", errors);
    console.log("allProcessData", allProcessData);
    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        const response = await processService.ProcessRegistryWebAction(
          allProcessData,
          siteUniqueId,
          languageCode
        );
        if (response.success) {
          handleSuccess(processLanuage, t("web.common.successfullyupdated"));
        } else {
          handleError(processLanuage, t("web.common.somethingwentwrong"));
        }
        setIsLoading(false);
      } catch (error) {
        handleError(processLanuage, t("web.common.somethingwentwrong"));
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    } else {
      return;
    }
  };
  const handleSuccess = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastSuccess title={titleName} description={message} />,
      "success"
    );
  };
  const handleError = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastError title={titleName} description={message} />,
      "error"
    );
  };
  const handleWarning = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastWarning title={titleName} description={message} />,
      "warning"
    );
  };
  return (
    <div className="w-full h-auto">
      <PageHeader
        title={allGetStarted?.[0]?.labelValue || ""}
        hasSaveBtn={isViewOnly === 1 ? false : true}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        allGetStarted={allGetStarted}
        overridden={overridden}
        isGetStartedAvailable={isGetStartedAvailable}
      />

      {/* {allGetStarted && allGetStarted.length > 0 && (
        <>
          <ProcessDetailHeader
            allGetStarted={allGetStarted}
            setAllGetStarted={setAllGetStarted}
            isGetStartedAvailable={isGetStartedAvailable}
            title={allProcessData?.labelValue}
            isLoading={isLoading}
          />
        </>
      )} */}
      {allProcessData && (
        <>
          <OptionOneMain
            allProcessData={allProcessData}
            setAllProcessData={setAllProcessData}
            isLoading={isLoading}
            isViewOnly={isViewOnly}
            validationErrors={validationErrors}
          />
        </>
      )}
      {pageLoader && <PageLoader />}
    </div>
  );
}
export default OptionOneWrapper;
