import { ReactNode, useEffect, useRef, useState } from "react";
// import { AddIcon, HidePassword, ShowPassword } from "../../svg";

interface InputFieldProps {
  labelName?: string;
  type?: string;
  id: string;
  isRequired?: boolean;
  value?: string;
  onChange?: (event: any) => void;
  hasAddBtn?: boolean;
  handleAdd?: (e: any) => void;
  focus?: boolean;
  error?: string;
  textColor?: string;
  formHandle?: any;
  isReadOnly?: boolean;
  fieldIcon?: ReactNode;
  maxLength?: any;
  isLightThemeOnly?: boolean;
}
interface TextAreaFieldProps {
  labelName: string;
  type?: string;
  id: string;
  isRequired?: boolean;
  error?: string;
  formHandle?: any;
  isReadOnly?: boolean;
  value?: string;
  onChange?: (value: string) => void;
}
interface Option {
  label: string;
  value: string;
}
interface SelectFieldProps {
  labelName: string;
  id: string;
  isRequired?: boolean;
  error?: string;
  options: Option[];
}

export const InputField = ({
  labelName,
  type,
  id,
  isRequired,
  value,
  formHandle,
  error,
  fieldIcon,
  textColor = "text-io-black dark:text-io-white",
}: InputFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div className="relative">
      <input
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        {...formHandle}
        name={id}
        className={`peer block w-full appearance-none border border-io-gray-c1 dark:border-io-black-48 bg-transparent py-[1.2rem] px-[1.7rem] pl-[1.5rem] text-[1.4rem] ${
          textColor ? textColor : ""
        } focus:border-io-primary rounded-[3px] focus:outline-none focus:ring-0`}
        placeholder=" "
        value={value}
      />
      {labelName && (
        <label className="absolute top-5 bg-io-white dark:bg-io-black-15 block px-2 origin-[0] left-[1rem] -translate-y-9 transform text-[1.2rem] text-io-gray-33 dark:text-io-black-d1 duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-[1rem] peer-focus:-translate-y-9  pointer-events-none">
          {labelName} {isRequired && <span className="text-red-500">*</span>}{" "}
        </label>
      )}
      {fieldIcon && (
        <div className="w-[2rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]">
          {fieldIcon}
        </div>
      )}
      {error && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
          {error}
        </p>
      )}
    </div>
  );
};

export const InputFieldWithoutForm = ({
  labelName,
  type,
  id,
  isRequired,
  value,
  onChange,
  hasAddBtn,
  handleAdd,
  focus,
  error,
  textColor = "text-io-black dark:text-io-white",
  isReadOnly = false,
  isLightThemeOnly,
  maxLength = 100,
  fieldIcon,
}: InputFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus, value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange !== undefined) {
      onChange(event.target.value);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="relative">
      <input
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        min={type === "number" ? 0 : ""}
        name={id}
        ref={inputRef}
        readOnly={isReadOnly}
        className={`peer block w-full appearance-none border ${
          isLightThemeOnly
            ? "border-io-gray-c1 "
            : "border-io-gray-c1 dark:border-io-black-48"
        } bg-transparent py-[1.2rem] px-[1.7rem] pl-[1.5rem] text-[1.4rem] ${
          textColor ? textColor : ""
        } focus:border-io-primary rounded-[3px] focus:outline-none focus:ring-0`}
        placeholder=" "
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
      />
      {labelName && (
        <label
          className={`absolute top-5 ${
            isLightThemeOnly
              ? "bg-io-white text-io-gray-33"
              : "bg-io-white text-io-gray-33 dark:bg-io-black-15 dark:text-io-white"
          }  block px-2 origin-[0] left-[1rem] -translate-y-9 transform text-[1.2rem]   duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-[1rem] peer-focus:-translate-y-9  pointer-events-none`}
        >
          {labelName} {isRequired && <span className="text-red-500">*</span>}{" "}
        </label>
      )}
      {fieldIcon && (
        <div className="w-[2rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]">
          {fieldIcon}
        </div>
      )}
      {error && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
          {error}
        </p>
      )}
    </div>
  );
};

export const TextAreaField = ({
  labelName,
  type,
  id,
  isRequired,
  error,
  formHandle,
  value,
  onChange,
  isReadOnly = false,
}: TextAreaFieldProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange !== undefined) {
      onChange(event.target.value);
    }
  };
  return (
    <div className="relative">
      <textarea
        rows={3}
        name={id}
        {...formHandle}
        readOnly={isReadOnly}
        className="peer block w-full appearance-none border border-io-gray-c1 dark:border-io-black-48 bg-transparent py-[1.2rem] px-[1.7rem] text-[1.4rem] text-io-black dark:text-io-white focus:border-io-primary rounded-[3px] focus:outline-none focus:ring-0"
        placeholder=" "
        value={value}
        onChange={handleChange}
      ></textarea>
      <label className="absolute top-5 bg-io-white dark:bg-io-black-15 block px-2 origin-[0] left-[1rem] -translate-y-9 transform text-[1.2rem] text-io-gray-33 dark:text-io-black-d1 duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-[1rem] peer-focus:-translate-y-9 pointer-events-none">
        {labelName}
      </label>
      {error && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
          {error}
        </p>
      )}
    </div>
  );
};

//   export const InputFieldWithoutForm = ({labelName, type, id, isRequired,value, onChange, hasAddBtn, handleAdd, focus, error, textColor="text-io-black dark:text-io-white"}:InputFieldProps) => {
//     const inputRef = useRef<HTMLInputElement>(null);
//     const [showPassword, setShowPassword] = useState<boolean>(false);
//     useEffect(() => {
//         if (focus && inputRef.current) {
//             inputRef.current.focus();
//         }
//     }, [focus, value]);

//     const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         if(onChange !== undefined) {
//             onChange(event.target.value);
//         }
//     };

//     const togglePasswordVisibility = () => {
//         setShowPassword(!showPassword);
//     };
//     return(
//         <div className="relative">
//           <input type={type === 'password' ? (showPassword ? 'text' : 'password') : type}  name={id} ref={inputRef} className={`peer block w-full appearance-none border border-io-gray-c1 dark:border-io-black-48 bg-transparent py-[1.2rem] px-[1.7rem] pl-[1.5rem] text-[1.4rem] ${textColor ? textColor : ""} focus:border-io-primary rounded-[3px] focus:outline-none focus:ring-0`} placeholder=" "  value={value} onChange={handleChange}/>
//           {labelName && <label className="absolute top-5 bg-io-white dark:bg-io-black-15 block px-2 origin-[0] left-[1rem] -translate-y-9 transform text-[1.2rem] text-io-gray-33 dark:text-io-black-72 duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-[1rem] peer-focus:-translate-y-9  pointer-events-none">{labelName} {isRequired && <span className="text-red-500">*</span>} </label>}
//           {type === 'password' && (
//               <button
//                 type="button"
//                 className="w-[2.1rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]"
//                 onClick={togglePasswordVisibility}
//               >
//                 {showPassword ? <ShowPassword /> : <HidePassword /> }
//               </button>
//           )}
//           {hasAddBtn && <button type="button" className="w-[1.8rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]" onClick={handleAdd}><AddIcon /></button>}
//           {error && <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">{error}</p>}
//         </div>
//     )
// }
