import { Outlet } from "react-router-dom";
import Header from "../components/global-layout/header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ToastifyMessage from "../lib/toastify";
import { ToastError, ToastSuccess, ToastWarning } from "../components/UI/toast";
function Layout() {
  const { ToastifyHandle } = ToastifyMessage();

  const handleSuccess = () => {
    ToastifyHandle(
      <ToastSuccess
        title="Login Successful"
        description="You have Successfully Login to your account."
      />,
      "success"
    );
  };

  const handleError = () => {
    ToastifyHandle(
      <ToastError
        title="Login Failed"
        description="Email or password is incorrect."
      />,
      "error"
    );
  };

  const handleWarning = () => {
    ToastifyHandle(
      <ToastWarning
        title="Oops! Something went wrong"
        description="An Unknown error occurred. Please Try again after a while."
      />,
      "warning"
    );
  };

  return (
    <>
      <div className="w-full h-screen overflow-hidden">
        <div className="w-full h-full flex flex-col">
          <Header />
          {/* <div className="flex justify-center items-center gap-5">
                    <button type="button" onClick={() => handleSuccess()}>success</button>
                    <button type="button" onClick={() => handleError()}>error</button>
                    <button type="button" onClick={() => handleWarning()}>warning</button>
                </div> */}
          <div className="w-full flex-1 relative">
            <div className="w-full h-full">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default Layout;
