import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "signature_pad";
import IconBtn from "../../form/icon-btn";
import { ResetIcon } from "../../svg";
import uploadFile from "../../../services/uploadFile";
import { v4 as uuidv4 } from "uuid";

function SignaturePadTest({
  questionData,
  selectedScreenId,
  questionUniqueId,
  fullProcessData,
  setAllProcessData,
}: any) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const signaturePadRef = useRef<any>();
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const generateGuid = () => uuidv4();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let url = "";
  const answerDetail =
    fullProcessData.screenDetail.answerDetail?.[selectedScreenId]?.[
      questionUniqueId
    ]?.defaultAnswer ?? [];

  if (answerDetail.length > 0) {
    const potentialUrl = answerDetail[0].url;
    url =
      potentialUrl.startsWith("http://") || potentialUrl.startsWith("https://")
        ? potentialUrl
        : "";
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      signaturePadRef.current = new SignaturePad(canvas);
      if (url) {
        signaturePadRef.current.fromDataURL(url, {
          ratio: 1,
          height: 300,
          width: 530,
        });
      }
      const resizeCanvas = () => {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d")?.scale(ratio, ratio);
      };
      window.addEventListener("resize", resizeCanvas);
      resizeCanvas();
      return () => {
        window.removeEventListener("resize", resizeCanvas);
      };
    }
  }, []);

  const clearSignature = () => {
    signaturePadRef.current?.clear();
    if (
      fullProcessData.screenDetail.answerDetail[selectedScreenId]?.[
        questionUniqueId
      ]
    ) {
      fullProcessData.screenDetail.answerDetail[selectedScreenId][
        questionUniqueId
      ].defaultAnswer = null;
    }
    setAllProcessData(fullProcessData);
  };

  function dataURLToBlob(dataURL: string) {
    const [header, data] = dataURL.split(",");
    const mimeString = header.match(/:(.*?);/)?.[1];
    const byteString = atob(data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([intArray], { type: mimeString });
  }

  const saveSignature = () => {
    if (signaturePadRef.current?.isEmpty()) {
      console.log("Please provide a signature first.");
    } else {
      setIsLoading(true);
      const dataURL = signaturePadRef.current.toDataURL();
      const blob = dataURLToBlob(dataURL);
      console.log(blob);
      sendImageDataToServer(blob, "png");
    }
  };

  const sendImageDataToServer = async (blob: any, extension: any) => {
    try {
      const { response, fileUrl } = await uploadFile(blob, extension);
      if (response.success) {
        handleImageData([
          {
            id: generateGuid(),
            url: response.data.fileUrl,
          },
        ]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading image:", error);
    }
  };

  const handleImageData = (newImages: any) => {
    if (
      fullProcessData.screenDetail.answerDetail[selectedScreenId]?.[
        questionUniqueId
      ]
    ) {
      fullProcessData.screenDetail.answerDetail[selectedScreenId][
        questionUniqueId
      ].defaultAnswer = newImages;
    }
    setAllProcessData(fullProcessData);
    setImageLoaded(false);
  };

  return (
    <div className="w-[60rem] relative">
      <canvas
        ref={canvasRef}
        className=" border border-dashed border-io-primary rounded-[0.6rem]"
        style={{ width: "100%", height: "300px" }}
      />
      <div className="w-auto h-auto absolute top-[3%] right-[1%]">
        <IconBtn
          size="w-[1.8rem]"
          icon={<ResetIcon />}
          handleClick={clearSignature}
        />
      </div>
      <div className="w-full flex justify-end items-center">
        <button
          className="w-auto h-auto inline-block text-[1.6rem] font-inter text-io-white font-normal bg-io-primary px-[2rem] py-[0.1rem] rounded-[0.3rem]"
          onClick={saveSignature}
        >
          {isLoading ? (
            <div className="w-full h-auto flex justify-center items-center gap-4">
              {" "}
              <div className="w-[2rem] h-[2rem]">
                <div className="btn-loader"></div>
              </div>
              Save
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
}
export default SignaturePadTest;
