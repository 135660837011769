import { blobServices } from "./InspectionOneServices";

async function uploadFile(
  file: File,
  extension: string
): Promise<{ response: any; fileUrl: string }> {
  return new Promise(async (resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        try {
          // Perform the file upload operation in the background
          let binaryData: ArrayBuffer | null = e.target.result as ArrayBuffer; // Directly use the ArrayBuffer

          // Make a call to your service function passing binaryData and extension
          const response = await blobServices.blobStorage(
            binaryData,
            extension
          );

          // Resolve the promise with the response and file URL
          resolve({ response, fileUrl: e.target.result.toString() });
        } catch (error) {
          console.error("Error uploading file:", error);
          reject(error); // Reject the promise if an error occurs
        }
      }
    };
    reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
  });
}

export default uploadFile;
