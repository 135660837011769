import { useEffect, useState } from "react";
import VoiceSearchInput from "../../form/voice-search-input";
import QuestionCard from "../../UI/question-card";
import ModalPageWrapper from "../../global-layout/modal-page-wrapper";
import IconBtn from "../../form/icon-btn";
import { PageModalCloseIcon } from "../../svg";
import Skeleton from "react-loading-skeleton";
import ImageEditor from "../../UI/image-editor";
import defaultImage from "../../../assets/images/default.png";
function OptionOneMain({
  allProcessData,
  setAllProcessData,
  isLoading,
  isViewOnly,
  validationErrors,
}: any) {
  const processData = allProcessData?.screenDetail?.mainScreenDetails;

  // Check if processData is defined and has at least one element
  const initialScreen =
    processData && processData.length > 0 ? processData[0] : null;
  const initialScreenId = initialScreen ? initialScreen.id : null;
  const [selectedScreen, setSelectedScreen] = useState<any>(initialScreen);
  const [selectedScreenId, setSelectedScreenId] =
    useState<any>(initialScreenId);
  const [showImagePreview, setShowImagePreview] = useState<boolean>(false);
  const [previewImageSrc, setPreviewImageSrc] = useState<any>(null);
  const [tabSearchData, setTabSearchData] = useState<any>(processData || []);
  const [previewImageUrl, setPreviewImageUrl] = useState(undefined);
  const [previewImgModal, setPreviewImgModal] = useState(false);
  useEffect(() => {
    // Update state when processData changes, if needed
    if (processData && processData.length > 0) {
      setSelectedScreen(processData[0]);
      setSelectedScreenId(processData[0].id);
      setTabSearchData(processData);
    }
  }, [processData]);

  const handleScreenClick = (screen: any) => {
    setSelectedScreen(screen);
    setSelectedScreenId(screen.id);
  };

  useEffect(() => {}, [selectedScreen]);

  const expandPreviewImage = (status: boolean, data: any) => {
    setShowImagePreview(status);
    setPreviewImageSrc(data.url);
  };
  const handleSearch = (e: any) => {
    const value = e.target.value;
    filterTabSearchData(value);
  };

  const filterTabSearchData = (searchQuery: string) => {
    const filteredData = processData.filter((item: any) => {
      return item.title.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setTabSearchData(filteredData);
  };
  const handleImagePreview = (imageUrl: any) => {
    setPreviewImgModal(true);
    setPreviewImageUrl(imageUrl);
  };
  const handleImageError = (event: any) => {
    event.target.src = defaultImage;
  };
  return (
    <>
      <div className="w-full h-auto flex gap-20 max-xl:gap-4 relative pt-10">
        <div className="w-[34rem] max-xl:w-[28rem] process-sidenav sticky top-[10.5rem]">
          {isLoading ? (
            <Skeleton width="100%" height="100%" borderRadius={15} />
          ) : (
            <div className="w-full h-full flex flex-col rounded-[1.5rem] border border-io-gray-df dark:border-io-black-48 bg-io-white dark:bg-[#1A1C28] pb-[1.4rem]">
              <div className="w-full h-auto px-[2rem] py-[1.4rem] border-b border-io-gray-df dark:border-io-black-48">
                <p className="text-[1.6rem] font-inter font-normal text-io-black dark:text-io-white leading-none">
                  {allProcessData?.processLogicName}
                </p>
              </div>
              <div className="w-full h-auto px-[2rem] pt-[2rem] pb-[1.4rem]">
                <VoiceSearchInput
                  withMic={false}
                  inputSize="small"
                  handleSearch={handleSearch}
                />
              </div>
              <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-medium px-[2rem]">
                {tabSearchData?.length > 0 ? (
                  tabSearchData.map((item: any, i: number) => (
                    <div
                      key={i}
                      className="w-full h-auto mb-2 cursor-pointer group"
                      onClick={() => handleScreenClick(item)}
                    >
                      <div
                        className={`w-full h-auto px-[1.2rem] py-4 rounded-[0.3rem] ${
                          selectedScreen.id === item.id
                            ? "bg-[#F2F8FF] dark:bg-[#182233]"
                            : "bg-io-white dark:bg-transparent"
                        } group-hover:bg-[#F2F8FF] dark:group-hover:bg-[#182233]`}
                      >
                        <p
                          className={`text-[1.4rem] font-inter font-normal ${
                            selectedScreen.id === item.id
                              ? "text-io-primary"
                              : "text-io-gray-66 dark:text-io-black-d1"
                          } ${
                            validationErrors[item.id]
                              ? "text-red-500 group-hover:text-red-500"
                              : "group-hover:text-io-primary"
                          }`}
                        >
                          {item.title}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-[1.6rem] font-inter font-normal text-io-gray-66 text-center italic">
                    No data Found
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex-1 h-auto ">
          <p className="text-[1.6rem] font-inter font-normal text-io-gray-9d italic">
            {isLoading ? (
              <Skeleton
                width={200}
                height={20}
                borderRadius={3}
                style={{ marginBottom: "10px" }}
              />
            ) : (
              <>
                {allProcessData?.screenDetail?.isOnlyMagicContainer !== 1 && (
                  <span>
                    {selectedScreen?.questionDetails.length} Questions Found
                  </span>
                )}
              </>
            )}
          </p>

          {selectedScreen?.questionDetails.length > 0 &&
            selectedScreen?.questionDetails.map(
              (screendetail: any, i: number) => {
                const screenId = selectedScreen.id;
                const filteredErrors = validationErrors?.[screenId] || {};
                return (
                  <QuestionCard
                    key={i}
                    item={screendetail}
                    fullProcessData={allProcessData}
                    setAllProcessData={setAllProcessData}
                    selectedScreenId={selectedScreenId}
                    index={i}
                    expandPreviewImage={expandPreviewImage}
                    isLoading={isLoading}
                    isViewOnly={isViewOnly}
                    handleImagePreview={handleImagePreview}
                    validationErrors={filteredErrors}
                  />
                );
              }
            )}
        </div>
      </div>
      {showImagePreview && (
        <ModalPageWrapper>
          <div className="w-full h-full flex justify-center items-center">
            <div className="w-[60%] h-auto bg-io-white dark:bg-io-black-1a rounded-[2.2rem] max-md:w-[68%] relative">
              <div className="w-full h-auto p-12 text-center">
                <div className="w-full h-auto flex justify-end mb-5">
                  <IconBtn
                    size="w-[3rem]"
                    icon={<PageModalCloseIcon />}
                    handleClick={() => setShowImagePreview(false)}
                  />
                </div>
                <div className="w-full h-auto">
                  {previewImageSrc && (
                    <ImageEditor imageSource={previewImageSrc} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalPageWrapper>
      )}
      {previewImgModal && (
        <ModalPageWrapper>
          <div className="w-full h-full flex justify-center items-center">
            <div className="w-[60%] h-auto bg-io-white dark:bg-io-black-1a rounded-[2.2rem] max-md:w-[68%] relative">
              <div className="w-full h-auto p-12 text-center">
                <div className="w-full h-auto flex justify-end mb-5">
                  <IconBtn
                    size="w-[3rem]"
                    icon={<PageModalCloseIcon />}
                    handleClick={() => setPreviewImgModal(false)}
                  />
                </div>
                <div className="w-full h-auto">
                  {previewImageUrl && (
                    <img
                      className="w-full h-[60vh] object-contain object-center"
                      src={previewImageUrl}
                      alt="Preview"
                      onError={(e) => handleImageError(e)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalPageWrapper>
      )}
    </>
  );
}
export default OptionOneMain;
