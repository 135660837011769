import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import CryptoJS from "crypto-js";
import i18n from "../../utils/localization/i18n";
import { useParams } from "react-router-dom";

interface MyContextType {
  language: string;
  setLanguage: (lang: string) => void;
}

const MyContext = createContext<MyContextType | undefined>(undefined);

interface MyContextProviderProps {
  children: ReactNode;
}

export const MyContextProvider: React.FC<MyContextProviderProps> = ({
  children,
}) => {
  const { languageCode } = useParams();
  console.log("languageCode", languageCode);
  const [language, setLanguage] = useState<string>(
    sessionStorage.getItem("language") || "en"
  );
  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const languageValue = sessionStorage.getItem("language");
    console.log("language", languageValue);
    i18n.changeLanguage(languageValue || "en");
  }, []);

  return (
    <MyContext.Provider
      value={{
        language,
        setLanguage: changeLanguage,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = (): MyContextType => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};
