import React, { useEffect, useRef, useState } from "react";
import { ImageDeleteIcon, UploadVideoIcon } from "../../svg";
import uploadFile from "../../../services/uploadFile";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

function VideoUpload({
  id,
  uploadedImageCount,
  selectedScreenId,
  questionUniqueId,
  fullProcessData,
  setAllProcessData,
}: any) {
  const { t } = useTranslation();
  const generateGuid = () => uuidv4();
  const [uploadedVideos, setUploadedVideos] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const uploadFiles = useRef<HTMLInputElement>(null);

  const handleFileInputChange = async (event: any) => {
    setIsLoading(true);
    if (
      uploadFiles.current &&
      uploadFiles.current.files &&
      uploadFiles.current.files.length > 0
    ) {
      const files = uploadFiles.current.files;

      let newVideos: any[] =
        fullProcessData?.screenDetail?.answerDetail?.[selectedScreenId]?.[
          questionUniqueId
        ].defaultAnswer || [];
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.name;
        const fileType = fileName.split(".").pop();
        const extension = fileType ? fileType.toLowerCase() : "";
        // if (!newVideos.some((image) => image.name === file.name)) {
        uploadPromises.push(
          uploadFile(file, extension).then(({ response, fileUrl }) => {
            if (response.success) {
              newVideos.push({
                id: generateGuid(),
                url: response.data.fileUrl,
              });
            }
          })
        );
        // }
      }
      try {
        await Promise.all(uploadPromises);
        setUploadedVideos(newVideos);
        setIsLoading(false);
        handleAllVideoData(newVideos);
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    }
  };

  const handleAllVideoData = (newVideos: any[]) => {
    if (fullProcessData.screenDetail.answerDetail[selectedScreenId]) {
      if (
        fullProcessData?.screenDetail?.answerDetail?.[selectedScreenId]?.[
          questionUniqueId
        ]
      ) {
        fullProcessData.screenDetail.answerDetail[selectedScreenId][
          questionUniqueId
        ].defaultAnswer = newVideos;
      }
    }
    setAllProcessData(fullProcessData);
  };

  const deleteSelectedFiles = (index: number) => {
    const updatedVideos = [
      ...fullProcessData?.screenDetail?.answerDetail?.[selectedScreenId]?.[
        questionUniqueId
      ].defaultAnswer,
    ];
    updatedVideos.splice(index, 1);
    setUploadedVideos(updatedVideos);
    handleAllVideoData(updatedVideos);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [fullProcessData.screenDetail.answerDetail[selectedScreenId]]);

  return (
    <div className="w-full h-auto">
      <div className="grid grid-cols-6 max-xl:grid-cols-4 gap-9 max-xl:gap-4">
        <div className="w-auto h-[15rem]">
          <input
            type="file"
            id={`uploadVideo${id}`}
            multiple={true}
            accept="video/*"
            onChange={handleFileInputChange}
            ref={uploadFiles}
            className="hidden w-0 h-0"
          />
          <label htmlFor={`uploadVideo${id}`} className="w-auto h-full">
            <div className="w-auto h-full py-[3.2rem] flex justify-center items-center rounded-[0.7rem] border border-dashed border-io-primary">
              <div className="w-auto h-auto max-xl:w-[4rem]">
                <UploadVideoIcon />
              </div>
            </div>
          </label>
        </div>
        {fullProcessData.screenDetail.answerDetail[selectedScreenId]?.[
          questionUniqueId
        ]?.defaultAnswer?.length > 0
          ? fullProcessData.screenDetail.answerDetail[selectedScreenId][
              questionUniqueId
            ].defaultAnswer.map((video: any, index: any) => (
              <div className="w-full h-[15rem] relative" key={index}>
                <video controls className="w-full h-full rounded-[0.7rem]">
                  <source src={video?.url} type={video.type} />
                  {t("web.assets.common.video")}
                </video>
                <div
                  className="w-[1.8rem] h-auto absolute bottom-[3%] right-[3%] cursor-pointer"
                  onClick={() => deleteSelectedFiles(index)}
                >
                  <ImageDeleteIcon />
                </div>
              </div>
            ))
          : ""}
        {isLoading && <Skeleton width={140} height={100} borderRadius={5} />}
      </div>
    </div>
  );
}

export default VideoUpload;
