import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { ExpandPrimaryIcon, ImageDeleteIcon, UploadImageIcon } from "../../svg";
import uploadFile from "../../../services/uploadFile";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import defaultImage from "../../../assets/images/default.png";
import IconBtn from "../icon-btn";
function ImageUpload({
  id,
  uploadedImageCount,
  selectedScreenId,
  questionUniqueId,
  fullProcessData,
  setAllProcessData,
  isEvidence,
  handleImagePreview,
}: any) {
  const { t } = useTranslation();
  const generateGuid = () => uuidv4();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);
  const uploadFiles = useRef<HTMLInputElement>(null);

  const handleFileInputChange = async (event: any) => {
    setIsLoading(true);
    if (
      uploadFiles.current &&
      uploadFiles.current.files &&
      uploadFiles.current.files.length > 0
    ) {
      const files = uploadFiles.current.files;
      let newImages: any[] = [];

      if (isEvidence) {
        newImages =
          fullProcessData?.screenDetail?.answerDetail?.[selectedScreenId]?.[
            questionUniqueId
          ].evidenceDetail || [];
      } else {
        newImages =
          fullProcessData?.screenDetail?.answerDetail?.[selectedScreenId]?.[
            questionUniqueId
          ].defaultAnswer || [];
      }

      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.name;
        const fileType = fileName.split(".").pop();
        const extension = fileType ? fileType.toLowerCase() : "";
        // if (!newImages.some((image) => image.name === file.name)) {
        uploadPromises.push(
          uploadFile(file, extension).then(({ response, fileUrl }) => {
            if (response.success) {
              newImages.push({
                id: generateGuid(),
                url: response.data.fileUrl,
              });
            }
          })
        );
        // }
      }
      try {
        await Promise.all(uploadPromises);
        setUploadedImages(newImages);
        setIsLoading(false);
        handleAllImageData(newImages);
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    }
  };

  const handleAllImageData = (newImages: any[]) => {
    const updatedProcessData = { ...fullProcessData };
    if (updatedProcessData.screenDetail.answerDetail[selectedScreenId]) {
      if (
        updatedProcessData.screenDetail.answerDetail[selectedScreenId][
          questionUniqueId
        ]
      ) {
        if (isEvidence) {
          updatedProcessData.screenDetail.answerDetail[selectedScreenId][
            questionUniqueId
          ].evidenceDetail = newImages;
        } else {
          updatedProcessData.screenDetail.answerDetail[selectedScreenId][
            questionUniqueId
          ].defaultAnswer = newImages;
        }
      }
    }
    setAllProcessData(updatedProcessData);
  };

  const deleteSelectedFiles = (index: number) => {
    let updatedImages = [];
    if (isEvidence) {
      updatedImages = [
        ...fullProcessData.screenDetail?.answerDetail?.[selectedScreenId]?.[
          questionUniqueId
        ].evidenceDetail,
      ];
    } else {
      updatedImages = [
        ...fullProcessData.screenDetail?.answerDetail?.[selectedScreenId]?.[
          questionUniqueId
        ].defaultAnswer,
      ];
    }
    updatedImages.splice(index, 1);
    setUploadedImages(updatedImages);
    handleAllImageData(updatedImages);
  };

  useEffect(() => {
    setIsLoading(false);
    const evidenceDetail =
      fullProcessData?.screenDetail?.answerDetail?.[selectedScreenId]?.[
        questionUniqueId
      ]?.evidenceDetail || [];
    uploadedImageCount(evidenceDetail.length);
  }, [fullProcessData, selectedScreenId, questionUniqueId]);
  const handleImageError = (event: any) => {
    event.target.src = defaultImage;
  };
  return (
    <div className="w-full h-auto">
      <div className="grid grid-cols-6 max-xl:grid-cols-4 gap-9 max-xl:gap-4">
        <div className="w-auto h-[15rem]">
          <input
            type="file"
            id={`uploadImage${id}`}
            multiple={true}
            onChange={handleFileInputChange}
            ref={uploadFiles}
            accept="Image/*"
            className="hidden w-0 h-0"
          />
          <label htmlFor={`uploadImage${id}`} className="w-auto h-auto">
            <div className="w-auto h-auto py-[3.2rem] flex justify-center items-center rounded-[0.7rem] border border-dashed border-io-primary">
              <div className="w-auto h-auto max-xl:w-[4rem]">
                <UploadImageIcon />
              </div>
            </div>
          </label>
        </div>
        {isEvidence === 1 &&
          (
            fullProcessData?.screenDetail?.answerDetail[selectedScreenId]?.[
              questionUniqueId
            ]?.evidenceDetail || []
          ).map((image: any, index: any) => (
            <div className="w-full h-[15rem] relative" key={index}>
              <div className="w-full h-full rounded-[0.7rem] overflow-hidden">
                <img
                  className="w-full object-cover object-center"
                  src={image?.url}
                  alt={`Uploaded ${index}`}
                  onError={(e) => handleImageError(e)}
                />
              </div>
              <div className="w-[1.6rem] h-auto absolute top-[3%] right-[3%]">
                <IconBtn
                  icon={<ExpandPrimaryIcon />}
                  size="w-[1.6rem]"
                  handleClick={() => handleImagePreview(image?.url)}
                />
              </div>
              <div
                className="w-[1.8rem] h-auto absolute bottom-[3%] right-[3%]"
                onClick={() => deleteSelectedFiles(index)}
              >
                <ImageDeleteIcon />
              </div>
            </div>
          ))}
        {isEvidence === 0 &&
          (
            fullProcessData?.screenDetail?.answerDetail[selectedScreenId]?.[
              questionUniqueId
            ]?.defaultAnswer || []
          ).map((image: any, index: any) => (
            <div className="w-full h-[15rem] relative" key={index}>
              <div className="w-full h-full rounded-[0.7rem] overflow-hidden">
                <img
                  className="w-full object-cover object-center"
                  src={image?.url}
                  alt={`Uploaded ${index}`}
                  onError={(e) => handleImageError(e)}
                />
              </div>
              <div className="w-[1.6rem] h-auto absolute top-[3%] right-[3%]">
                <IconBtn
                  icon={<ExpandPrimaryIcon />}
                  size="w-[1.6rem]"
                  handleClick={() => handleImagePreview(image?.url)}
                />
              </div>
              <div
                className="w-[1.8rem] h-auto absolute bottom-[3%] right-[3%] cursor-pointer"
                onClick={() => deleteSelectedFiles(index)}
              >
                <ImageDeleteIcon />
              </div>
            </div>
          ))}
        {isLoading && <Skeleton width={140} height={100} borderRadius={5} />}
      </div>
    </div>
  );
}
export default ImageUpload;
