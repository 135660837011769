import React, { useState, useMemo, useEffect, useRef } from "react";
import Select, { components, ActionMeta } from "react-select";

interface Option {
  id: string;
  label: string;
}

interface SelectInputWithoutFormProps {
  labelName: string;
  id: string;
  isRequired: boolean;
  options: any;
  getOptionLabel: any;
  getOptionValue: any;
  getSelection: (data: any) => void;
  defaultValue?: Option;
  error?: any;
}

interface MultiSelectInputWithoutFormProps {
  labelName: string;
  idName: string;
  error?: any;
  options: any;
  getOptionLabel?: any;
  getOptionValue?: any;
  isDisabled?: boolean;
  isOptionDisabled?: any;
  isReset?: boolean;
  isRequired?: boolean;
  defaultValue?: Option[];
  value?: any;
  onChange: (value: any) => void;
}
const SelectInputWithoutForm = ({
  labelName,
  id,
  isRequired,
  options,
  getOptionLabel,
  getOptionValue,
  getSelection,
  defaultValue,
}: SelectInputWithoutFormProps) => {
  const [selectedValue, setSelectedValue] = useState<Option | null>(
    defaultValue || null
  );
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleInputChange = (selectedOption: Option | null) => {
    setSelectedValue(selectedOption);
    setIsFocused(!!selectedOption);
  };

  useEffect(() => {
    if (selectedValue) {
      getSelection(selectedValue);
    }
  }, [selectedValue]);

  return (
    <div className="w-full h-auto relative">
      <label
        className={`transform absolute bg-io-white dark:bg-io-black-15 block px-2 origin-[0] left-[1rem] text-[1.2rem] text-io-gray-33 dark:text-io-white duration-300 z-10 ${
          isFocused || selectedValue
            ? "top-[-20%] translate-y-[0] left-[1rem]"
            : "top-[50%] translate-y-[-50%]"
        }`}
      >
        {labelName}
        {isRequired && <span className="text-red-500">*</span>}
      </label>
      <Select
        options={options}
        placeholder=""
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleInputChange}
        value={selectedValue}
        classNamePrefix="cus-select"
        menuPosition={"fixed"}
        isSearchable={false}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
    </div>
  );
};

const MultiSelectInputWithoutForm = ({
  labelName,
  idName,
  error,
  options,
  getOptionLabel,
  getOptionValue,
  isDisabled,
  isOptionDisabled,
  isReset,
  isRequired,
  defaultValue,
  value,
  onChange,
}: MultiSelectInputWithoutFormProps) => {
  const selectRef = useRef<any>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState<any>([]);

  useEffect(() => {
    if (isReset) {
      selectRef?.current?.clearValue();
    }
  }, [isReset]);

  const handleOnChange = (selectedOptions: any) => {
    if (onChange) {
      setInputValue(selectedOptions);
      onChange(selectedOptions);
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    if (inputValue.length === 0) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
      setIsFocused(true);
    }
  }, [defaultValue]);

  return (
    <>
      <div className="w-full h-auto relative">
        <label
          className={`transform select-none pointer-events-none absolute  bg-io-white dark:bg-io-black-15 block px-2 origin-[0] left-[1rem] text-[1.2rem] text-io-gray-33 dark:text-io-black-d1 duration-300 z-10 ${
            isFocused
              ? "top-[-16%] translate-y-[0] left-[1rem]"
              : "top-[50%] translate-y-[-50%]"
          }`}
        >
          {labelName}
          {isRequired && <span className="text-red-500">*</span>}
        </label>
        <Select
          options={options}
          closeMenuOnSelect={false}
          isSearchable={false}
          placeholder=""
          classNamePrefix="cus-multi-select"
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          isMulti={true}
          id="multipleselect"
          isClearable={false}
          menuPosition={"fixed"}
          isDisabled={isDisabled}
          isOptionDisabled={isOptionDisabled}
          ref={selectRef}
          value={inputValue}
          onChange={handleOnChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />

        {error && (
          <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
            {error}
          </p>
        )}
      </div>
    </>
  );
};

export { SelectInputWithoutForm, MultiSelectInputWithoutForm };
