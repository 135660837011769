import IconBtn from "../../form/icon-btn";
import { TextBtn } from "../../form/text-btn";
import ModalPageWrapper from "../../global-layout/modal-page-wrapper";
import { PageModalCloseIcon, ServerWarningIcon } from "../../svg";

interface ErrorPopupProps {
  handleClose: () => void;
}

function ErrorPopup({ handleClose }: ErrorPopupProps) {
  return (
    <ModalPageWrapper>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[40%] h-auto bg-io-white rounded-[2.2rem] max-md:w-[68%]">
          <div className="w-full h-auto p-12 text-center">
            <div className="w-full h-auto flex justify-end">
              <IconBtn
                size="w-[3rem]"
                icon={<PageModalCloseIcon />}
                handleClick={handleClose}
              />
            </div>
            <div className="w-[10rem] h-auto mx-auto mb-[3.5rem] max-md:w-[10rem] max-md:mb-[2rem]">
              <ServerWarningIcon />
            </div>
            <div className="w-full h-auto mb-[5%]">
              <h1 className="text-[3.2rem] font-inter font-medium text-io-black mb-4">
                Oops! Something went wrong
              </h1>
              <div className="w-full h-auto  ">
                <div className="text-[1.8rem] font-inter font-normal text-io-gray-66">
                  An Unknown error occurred. Please Try again after a while.
                </div>
              </div>
            </div>
            <div className="w-full h-auto flex justify-center items-center gap-4 mb-[5%]">
              <TextBtn
                label="Try Again"
                btnStyle="fill-primary"
                btnType="button"
                handleClick={handleClose}
              />
            </div>
            <div className="w-full h-auto">
              <p className="text-[1.6rem] font-inter font-normal text-io-gray-66 text-center">
                For help, visit{" "}
                <a href="#" className="text-io-primary">
                  help.JSN.com
                </a>{" "}
                or contact technical support{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default ErrorPopup;
