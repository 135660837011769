import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Layout from "./pages/layout";
import NotFound from "./pages/not-found";
import OptionOne from "./pages/option-one";
import OptionTwo from "./pages/option-two";
import "react-loading-skeleton/dist/skeleton.css";
import { useLayoutEffect, useState } from "react";
import ErrorPopup from "./components/UI/error-popup";
import PageLoader from "./components/UI/page-loader";
import { SkeletonTheme } from "react-loading-skeleton";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: (
          <Navigate
            to={"/InspectionOne/:authToken/:siteUniqueId/:languageCode"}
          />
        ),
      },
      {
        path: "InspectionOne/:authToken/:siteUniqueId/:languageCode",
        element: <OptionOne />,
      },
      {
        path: "option-two/:authToken/:siteUniqueId/:languageCode",
        element: <OptionTwo />,
      },
    ],
  },
]);

function App() {
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  useLayoutEffect(() => {
    const storedTheme = sessionStorage.getItem("isDarkMode");
    if (storedTheme !== null) {
      console.log(storedTheme);
      setIsDarkMode(JSON.parse(storedTheme));
    }
  }, []);
  return (
    <>
      <SkeletonTheme
        baseColor={isDarkMode === true ? "#232432" : "#ebebeb"}
        highlightColor={isDarkMode === true ? "#484952" : "#f5f5f5"}
      >
        <RouterProvider router={router}></RouterProvider>
      </SkeletonTheme>
      {isServerError && (
        <ErrorPopup handleClose={() => setIsServerError(false)} />
      )}
      {pageLoader && <PageLoader />}
    </>
  );
}

export default App;
